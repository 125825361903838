@use "../../theme/sass/get-theme";

@mixin static-pages-common-styling {
  font-size: 1.143rem;
  line-height: 1.714rem;
}

.static-pages-heading {
  margin-top: 2.857rem;
  margin-bottom: 1.714rem;
  font-size: 1.429rem;
  font-weight: bold;
  line-height: 2.143rem;
  margin-left: 10%;
  margin-right: 10%;
}

.static-pages-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.static-pages-sub-container {
  display: inline-block;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 1.143rem;
  margin-bottom: 3.429rem;
  width: 100%;
}

.static-pages-title {
  font-size: 1.429rem;
  line-height: 2.143rem;
  font-family: "MichelinUnitTitling-Bold";
  @include get-theme.get-theme(color, blue-primary);
  margin-bottom: 1.071rem;
}

.static-pages-container-padding {
  padding: 2.071rem 2.286rem 2.857rem;
}

.static-pages-sub-heading {
  font-size: 2.286rem;
  font-family: "MichelinUnitTitling-Bold";
  line-height: 3.429rem;
  @include get-theme.get-theme(color, blue-primary);
  margin-bottom: 1.571rem;
}

.static-pages-points {
  font-size: 1.429rem;
  font-family: "MichelinUnitTitling-Bold";
  line-height: 2.143rem;
  @include get-theme.get-theme(color, blue-primary);
  margin-top: 2.929rem;
  margin-bottom: 1.071rem;
}

.static-pages-summary {
  @include static-pages-common-styling;
  font-weight: bold;
  @include get-theme.get-theme(color, text-grey);
  margin-bottom: 0.571rem;
}

.static-pages-bullets {
  @include static-pages-common-styling;
  font-weight: bold;
  margin-bottom: 1.143rem;
  @include get-theme.get-theme(color, black-text);
}

.static-pages-points-with-content {
  @include static-pages-common-styling;
  @include get-theme.get-theme(color, black-text);
}

.static-pages-points-with-content-margin {
  margin-bottom: 1.143rem;
}

.static-pages-keyword-bold {
  font-weight: bold;
}

.static-pages-bullets-keyword {
  font-weight: bold;
}

.static-pages-content {
  display: flex;
  flex-direction: column;
  gap: 1.143rem;
  @include static-pages-common-styling;
  @include get-theme.get-theme(color, black-text);
}

.static-pages-content-with-link {
  display: block;
  @include static-pages-common-styling;
  @include get-theme.get-theme(color, black-text);
}

.static-pages-keyword-with-link > a {
  @include get-theme.get-theme(color, blue-primary);
  text-decoration: underline;
}

.static-pages-content-with-link > .static-pages-keyword-with-link > a {
  @include get-theme.get-theme(color, blue-primary);
  text-decoration: underline;
}

.static-pages-table {
  border-collapse: separate;
  @include get-theme.get-theme(border, black-text-shade, 1px, solid);
  border-spacing: 0;
  border-radius: 0.286rem;
}

.static-pages-table tr {
  @include get-theme.get-theme(border-bottom, silver-shade-one, 1px, solid);
}

.static-pages-table tr:last-child {
  @include get-theme.get-theme(border, black-text-shade, 1px, solid);
}

.static-pages-table td {
  @include get-theme.get-theme(border-right, silver-shade-one, 1px, solid);
  padding: 1.714rem;
}

.static-pages-table-first-row > td {
  @include get-theme.get-theme(border-bottom, silver-shade-one, 1px, solid);
}

.static-pages-privacy-table > tbody > tr > td {
  @include get-theme.get-theme(border-bottom, silver-shade-one, 1px, solid);
}

.static-pages-privacy-table > tbody > tr:last-child > td {
  @include get-theme.get-theme(border-bottom, silver-shade-one, 0px, solid);
}

.static-pages-privacy-point-margin {
  margin-top: 1.143rem;
}

.static-pages-table td:last-child {
  @include get-theme.get-theme(border-right, black-text-shade, 0px, solid);
}

@media screen and (max-width: 1680px) {
  .static-pages-heading {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .static-pages-sub-heading {
    font-size: 1.714rem;
    line-height: 2.571rem;
  }

  .static-pages-title {
    font-size: 1.286rem;
    line-height: 1.929rem;
  }

  .static-pages-points {
    margin-top: 2.357rem;
  }

  .static-pages-summary {
    font-size: 1rem;
  }
  .static-pages-bullets {
    font-size: 1rem;
  }
  .static-pages-points-with-content {
    font-size: 1rem;
  }
  .static-pages-content {
    font-size: 1rem;
  }
  .static-pages-content-with-link {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .static-pages-heading {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .static-pages-sub-heading {
    font-size: 1.714rem;
    line-height: 2.571rem;
  }

  .static-pages-title {
    font-size: 1.286rem;
    line-height: 1.929rem;
  }

  .static-pages-points {
    margin-top: 2.357rem;
  }

  .static-pages-summary {
    font-size: 1rem;
  }
  .static-pages-bullets {
    font-size: 1rem;
  }
  .static-pages-points-with-content {
    font-size: 1rem;
  }
  .static-pages-content {
    font-size: 1rem;
  }
  .static-pages-content-with-link {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .static-pages-heading {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .static-pages-sub-heading {
    font-size: 1.714rem;
    line-height: 2.571rem;
  }

  .static-pages-title {
    font-size: 1.286rem;
    line-height: 1.929rem;
  }

  .static-pages-points {
    margin-top: 2.357rem;
  }

  .static-pages-summary {
    font-size: 1rem;
  }
  .static-pages-bullets {
    font-size: 1rem;
  }
  .static-pages-points-with-content {
    font-size: 1rem;
  }
  .static-pages-content {
    font-size: 1rem;
  }
  .static-pages-content-with-link {
    font-size: 1rem;
  }
}
