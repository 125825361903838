@use "../../../theme/sass/get-theme";

.app-image-with-description {
  display: flex;
  gap: 1.5rem;
}

.app-description-wrapper {
  max-height: 13.75rem;
}

.app-description-wrapper > * {
  margin-bottom: 0.5rem;
}

.app-details-app-name {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  @include get-theme.get-theme(color, grey-shade-one);
}

.app-status-package-name {
  display: flex;
}

.app-status {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

.app-details-package-name {
  font-size: 0.75rem;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

.account-name {
  line-height: 1.71rem;
  @include get-theme.get-theme(color, description-text);
}

.app-reviews-downloads {
  display: flex;
}

.statistics {
  font-size: 0.75rem;
  line-height: 1.5;
  text-align: center;
  @include get-theme.get-theme(color, text-grey);
}

.line {
  border: solid 0.063rem #979797;
  margin-left: 0.469rem;
  margin-right: 0.469rem;
}

.line-statistics {
  border: solid 0.063rem #979797;
  margin-left: 1.969rem;
  margin-right: 1.969rem;
}

.app-reviews-downloads-black-text {
  line-height: 1.5rem;
  margin-bottom: 0.25rem;
}
