@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.app-details-sub-container {
  display: flex;
  flex-direction: column;
  margin: 2.3rem 10%;
}

.app-details-heading {
  @include get-theme.get-theme(color, text-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.app-name-container {
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app-details-icon {
  margin-left: 3.3rem;
  height: 2rem;
  width: 2rem;
}

.app-image-with-description-subcontainer {
  width: fit-content;
}

.app-image-with-description-wrapper {
  display: flex;
  margin-top: 1.5rem;
}

.about-app-label {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, grey-shade-one);
}

.app-description {
  @include get-theme.get-theme(color, text-dark-grey);
}

.app-description-text {
  display: -webkit-box;
  line-clamp: 5;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: calc(5 * 1.71 * 14px);
  line-height: 1.71;
}

.read-more {
  line-clamp: unset;
  -webkit-line-clamp: unset;
  max-height: none;
}

.show-more-div {
  text-decoration: underline;
  @include get-theme.get-theme(color, blue-shade-one);
}

.app-meta-data-container {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.app-secrets-reviews-wrapper {
  width: 50%;
}

.app-meta-data-subcontainer {
  width: 40%;
}

.app-meta-data-details {
  width: 70%;
}

.app-reviews-ratings-main-div {
  display: flex;
  justify-content: space-between;
}

.no-reviews-label {
  line-height: 1.71;
  @include get-theme.get-theme(color, text-dark-grey);
}

@media screen and (max-width: 1920px) {
  .app-meta-data-flex {
    display: flex;
    justify-content: space-between;
  }
  .app-image-with-description-subcontainer {
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .app-details-play-store {
    height: 1.5rem;
    width: 6.75rem;
  }
}
