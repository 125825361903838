@use "./theme/sass/get-theme";

.app {
  @include get-theme.get-theme(background-color, white-primary);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  min-height: 100vh;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 14px;
  vertical-align: baseline;
  font-family: "Noto Sans", sans-serif;
  max-width: 100%;
  height: 100vh;
}

@font-face {
  font-family: "MichelinUnitText-Bold-Italic";
  src: url("./fonts/MichelinUnitText-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "MichelinUnitText";
  src: url("./fonts/MichelinUnitText-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "MichelinUnitTitling-Bold";
  src: url("./fonts/MichelinUnitTitling-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "MichelinUnitTitling-Bold";
  src: url("./fonts/MichelinUnitTitling-Bold.ttf") format("truetype");
}

@media screen and (max-width: 410) {
  body {
    font-size: 12px;
  }
}

.main-container {
  @include get-theme.get-theme(background-color, background-primary);
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.display-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.display-row {
  display: flex;
  flex-direction: row;
}
.heading {
  font-size: 1.5rem;
  @include get-theme.get-theme(color, text-dark);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.back-icon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 8px 8px 8px;
  border-radius: 4px;
  @include get-theme.get-theme(background-color, blue-shade-three);
  margin-right: 1.6rem;
}
.horizontal-line {
  flex: 1;
  height: 1px;
  @include get-theme.get-theme(border, border-grey, 1px, solid);
  @include get-theme.get-theme(background-color, border-grey);
}
.icon-size {
  width: 1.14rem;
  height: 1.14rem;
}
.card-icon {
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width: 1280px) {
  .card-icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
