@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.persona-details-label-with-icon {
  display: flex;
  align-items: center;
}

.persona-details-heading {
  @include get-theme.get-theme(color, text-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.persona-details-icon-with-name {
  display: flex;
}

.persona-details-labels {
  font-size: 1.429rem;
  line-height: 2.143rem;
  font-weight: bold;
  @include get-theme.get-theme(color, black-text);
}

.persona-details-persona-name {
  margin-top: 2.5rem;
}

.user-placeholder-icon {
  margin-top: 1.714rem;
  margin-right: 1.714rem;
  height: 6.857rem;
  width: 6.857rem;
}

.persona-details-user-name {
  margin-top: 1.714rem;
  margin-bottom: 1.143rem;
  font-size: 1.429rem;
  line-height: 2.143rem;
  font-weight: bold;
  @include get-theme.get-theme(color, grey-shade-one);
}

.persona-details-business-information-section {
  display: flex;
  gap: 5.143rem;
  font-size: 0.857rem;
  line-height: 1.286rem;
  margin-bottom: 0.571rem;
  @include get-theme.get-theme(color, black-text);
}

.persona-details-business-customer-section {
  margin-bottom: 0.571rem;
}

.persona-details-business-customer-section-key {
  font-weight: 600;
}

.persona-details-email-section {
  display: flex;
  margin-top: 1.143rem;
  margin-bottom: 1.714rem;
  gap: 2.25rem;
}

.persona-details-email-section-keys {
  font-size: 1.143rem;
  line-height: 1.714rem;
  margin-bottom: 0.571rem;
  @include get-theme.get-theme(color, black-text);
}

.persona-details-email-section-values {
  font-size: 0.857rem;
  line-height: 1.286rem;
  @include get-theme.get-theme(color, text-grey);
}

.persona-details-text-area-sections {
  padding: 1.714rem;
  @include get-theme.get-theme(background-color, white-primary);
  margin-bottom: 1.714rem;
}

.persona-details-text-area-labels {
  font-size: 1.143rem;
  line-height: 1.714rem;
  font-weight: bold;
}

.persona-details-text-area-values {
  font-size: 1rem;
  line-height: 1.714rem;
  margin-top: 1.143rem;
  @include get-theme.get-theme(color, text-dark-grey);
}

.assigned-apps {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  max-height: calc(3 * 5.14rem) + 2.14rem;
  margin-top: 1.14rem;
  row-gap: 1.14rem;
  column-gap: 1.14rem;
  align-items: center;
}

.persona-details-no-assigned-apps {
  line-height: 1.714rem;
  @include get-theme.get-theme(color, text-grey);
}

.persona-details-no-apps-info-icon {
  height: 1.143rem;
  width: 1.143rem;
}

@media screen and (max-width: 1280px) {
  .persona-details-labels {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .persona-details-persona-name {
    margin-top: 1.714rem;
  }

  .user-placeholder-icon {
    margin-top: 1.143rem;
    margin-right: 1.143rem;
    height: 5.714rem;
    width: 5.714rem;
  }

  .persona-details-user-name {
    margin-top: 1.143rem;
    margin-bottom: 0.571rem;
    font-size: 1rem;
    line-height: 1.714rem;
  }

  .persona-details-business-information-section {
    font-size: 0.714rem;
    line-height: 1.286rem;
  }

  .persona-details-email-section-keys {
    font-size: 1rem;
  }

  .persona-details-email-section-values {
    font-size: 0.714rem;
  }

  .persona-details-text-area-sections {
    padding: 1.143rem;
  }

  .persona-details-text-area-labels {
    font-size: 1rem;
  }

  .persona-details-text-area-values {
    font-size: 0.857rem;
    line-height: 1.286rem;
    margin-top: 0.571rem;
  }

  .assigned-apps {
    max-height: calc(3 * 3.43rem) + 1.3rem;
    margin-top: 1.14rem;
    row-gap: 0.57rem;
    column-gap: 0.57rem;
  }
}
