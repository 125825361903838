@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.card-container {
  padding: 1.14rem 2.5rem 1.14rem 1.14rem;
  border-radius: 4px;

  margin: 1.14rem 0;
  display: flex;
  flex-direction: row;

  .profile-icon {
    width: 1.7rem;
    height: 1.7rem;
  }

  .content-container {
    width: 100%;
    margin-left: 0.57rem;
    display: flex;
    flex-direction: column;

    .user-name {
      margin-bottom: 0.57rem;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.5;
      @include get-theme.get-theme(color, deep-black-text);
    }

    .ratings-container {
      display: inline-flex;
      align-items: center;
    }

    .ratings {
      width: fit-content;
    }

    .date {
      margin-left: 0.43rem;
      font-size: 0.71rem;
      @include get-theme.get-theme(color, grey-shade-two);
      line-height: 1.14rem;
      align-items: center;
      margin-top: 0.5rem;
    }
    .reply-date {
      margin-left: 0.43rem;
      font-size: 0.71rem;
      @include get-theme.get-theme(color, grey-shade-two);
      line-height: 1.14rem;

      margin-top: 0.2rem;
    }
    .card-description {
      line-height: 1.5;
      margin: 8px 0 0;
      font-size: 1rem;
      text-wrap: wrap;
      @include get-theme.get-theme(color, deep-black-text);
    }
  }

  .review-reply-container {
    display: flex;
    flex-direction: column;
    padding: 1.14rem 1.14rem 1.14rem 1.14rem;
    margin-top: 1.5rem;
    gap: 0.5rem;

    .review-reply-heading-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .review-reply-heading {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        font-size: 1rem;
        @include get-theme.get-theme(color, deep-black-text);
      }
    }
    .reply-discription {
      font-size: 1rem;
      line-height: 1.5;
      @include get-theme.get-theme(color, deep-black-text);
    }
  }
  .reply-link {
    margin-top: 1.2rem;
    font-size: 1.1rem;
    @include get-theme.get-theme(color, blue-primary);
  }
  .reviewReplyTextEditor {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    textarea {
      width: calc(100% - 2rem) !important;
      padding: 1rem !important;
    }
  }
  .submit-review-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 0.8rem;
    .cancel-review {
      width: 8rem;
      height: 3rem !important;
      @include button-theme.secondary-button-theme;
      cursor: pointer;
      font-size: 1rem;
      margin-right: 1rem;
    }
    .submit-review {
      width: 8rem;
      height: 3rem !important;
      @include button-theme.button-theme;
      cursor: pointer;
      font-size: 1rem;
    }
  }
}

.review-card-background-white {
  @include get-theme.get-theme(background-color, white-primary);
}

.review-card-background-grey {
  @include get-theme.get-theme(background-color, input-background);
}

@media only screen and (max-width: 1280px) {
  .card-container {
    .submit-review-buttons-container {
      .cancel-review {
        width: 6rem;
        height: 2rem !important;
      }
      .submit-review {
        width: 6rem;
        height: 2rem !important;
      }
    }
  }
}
