@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.heading-container {
  margin: 0 10%;
}
.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.form {
  display: inline-block;
  @include get-theme.get-theme(background-color, white-primary);
  margin: 29px 0;
  border-radius: 16px;
  width: 100%;

  .form-padding {
    padding: 2.2rem 3.5rem 2.8rem 2.8rem;
  }
}

.lable {
  font-size: 14px;
  line-height: 1.71;
  @include get-theme.get-theme(color, text-dark);
  margin-top: 17px;
  margin-bottom: 8px;
  padding: 0;
}

.banner {
  width: 100%;
  margin-bottom: 32px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.bold {
  font-weight: bold;
}

.title-text {
  font-size: 1.28rem;
  @include get-theme.get-theme(color, blue-primary);
}

.spacer {
  align-items: center;
  justify-content: space-between;
}

.mandatory-fields {
  font-size: 12px;
  font-style: italic;
  line-height: 1.5;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8%;
}

textarea::placeholder {
  font-family: "Noto Sans";
}

.upload-icon {
  width: 7rem;
  height: 7rem;
  margin: 0.57rem 0 0 0;
  cursor: pointer;
}

.app-icon-border-radius {
  border-radius: 4px;
}

.delete-icon-hide {
  display: none;
}

.upload-text {
  margin-left: 1.14rem;
  line-height: 1.71;
  @include get-theme.get-theme(color, text-grey);
  display: flex;
  flex-direction: column;
}

.full-width {
  width: 95.3%;
  display: flex;
  height: 1rem;
  @include get-theme.get-theme(background-color, input-background);
  @include get-theme.get-theme(border, input-background, 1px, solid);
  border-radius: 4px;
  margin-bottom: 7px;
  font-size: 1rem;
}

.publisher-section {
  margin-top: 37px;
}

.submit-button {
  @include button-theme.button-theme;
  cursor: pointer;
}
.button-container {
  margin: 40px 0px 46px auto;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.cancel-button {
  @include button-theme.secondary-button-theme;
  cursor: pointer;
}
.fields-container {
  margin: 0 20% 0 0;
}

.delete-app-icon {
  cursor: pointer;
  width: 1.57rem;
  height: 1.57rem;
  margin-left: -0.86rem;
}

.app-icon-wrapper {
  position: relative;
  width: 7.6rem;
  display: flex;
}

.remove-app-icon-margin {
  margin-top: 6px;
}

.delete-app-icon-label {
  font-size: 12px;
  @include get-theme.get-theme(color, text-red);
}

.overlay {
  position: absolute;
  bottom: 0;
  height: 26px;
  @include get-theme.get-theme(background-color, input-background);
  width: 98px;
  opacity: 1;
  text-align: center;
}

.fields-disabled {
  @include get-theme.get-theme(background-color, white-text);
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    margin: 0;
  }
  .form {
    width: 90%;
  }
}
