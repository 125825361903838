@use "../../../theme/sass/get-theme";

.card-content {
  display: flex;
  flex-direction: row;
  gap: 1.143rem;
  width: 34.79rem;
  height: 15rem;

  .app-logo-details {
    .app-logo-container {
      width: 100px;
      height: 100px;

      .app-logo {
        width: 100%;
        object-fit: contain;
        border-radius: 1.07rem;
        border: 1px solid #ffffff;
      }
    }

    .app-no-of-downloads-container {
      .app-no-of-downloads {
        @include get-theme.get-theme(color, text-dark);
        font-size: 1.14rem;
        padding-top: 1.14rem;
        text-align: center;
      }

      .app-no-of-downloads-subtext {
        font-size: 0.86rem;
        text-align: center;
        @include get-theme.get-theme(color, text-grey);
      }
    }
  }

  .icons-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    position: relative;
  }

  .my-apps-card-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .app-content-container {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
    flex: 1 1 auto;

    .app-name-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .app-name {
        font-family: NotoSans;
        font-weight: bold;
        font-size: 1.29rem;
        @include get-theme.get-theme(color, text-dark-grey);
      }
    }

    .app-rating-container {
      .display-rating {
        gap: 0.5rem;
        align-items: center;
      }

      .app-rating {
        font-size: 1.14rem;
        @include get-theme.get-theme(color, text-dark);
        margin: 1rem;
      }

      .app-no-of-reviews {
        font-size: 0.86rem;

        @include get-theme.get-theme(color, text-grey);
      }
    }

    .app-account-holder-name {
      font-size: 0.86rem;
      @include get-theme.get-theme(color, text-grey);
    }

    .app-publish-date {
      font-size: 0.86rem;
      @include get-theme.get-theme(color, text-grey);
    }

    .app-info {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      @include get-theme.get-theme(color, text-grey);
    }
  }

  .analytics-by-status-app-status {
    @include get-theme.get-theme(color, text-dark);
    font-size: 1rem;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1671px) {
  .card-content {
    width: 26rem;
  }
}

@media only screen and (min-width: 1776px) AND (max-width: 1876px) {
  .card-content {
    width: 32rem;
  }
}

@media only screen and (min-width: 1672px) AND (max-width: 1775px) {
  .card-content {
    width: 30rem;
  }
}
