@use "../../theme/sass/get-theme";

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10%;
  height: 3.3rem;
  flex-shrink: 0;
  @include get-theme.get-theme(background-color, white-primary);

  .footer-links {
    display: flex;
    margin-right: auto;
    font-size: 0.857rem;
    line-height: 1.286rem;
    gap: 1.714rem;
  }

  .footer-links > a {
    @include get-theme.get-theme(color, blue-primary);
    text-decoration: none;
  }

  .footer-content {
    font-size: 1rem;
    margin-left: auto;
  }
}
