@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";
  
  .team-table-container {
    margin-top: 1.5rem; 
    margin-bottom: 2.29rem;
    padding: 0 1.5rem;
    width: auto;
    overflow-x: auto;
  
    .team-table {
      width: 100%;
      border-collapse: collapse;
  
      thead {
          @include get-theme.get-theme(background-color, table-header-light-blue);
  
        th {
          padding-top: 1.14rem;
          padding-bottom: 1.14rem;
          padding-left: 2.86rem;
          text-align: left;
          font-weight: bold;
          @include get-theme.get-theme(border-bottom, white-primary, 1px, solid);
          size: 1.29rem;
          line-height: 1.99rem;
          @include get-theme.get-theme(color, black-text);

          &.name-column {
            width: 30%;
          }
    
          &.email-column {
            width: 30%; 
          }
    
          &.role-column {
            width: 20%; 
          }
    
          &.actions-column {
            width: 20%;
            text-align: left;
          }
        }
      }
  
      tbody {
        tr {
          &.row-even {
              @include get-theme.get-theme(background-color, table-main-header-light-blue)
          }
  
          &.row-odd {
            @include get-theme.get-theme(background-color, table-row-background-even)
          }
  
          td {
            padding-top: 1.14rem;
            padding-bottom: 1.14rem;
            padding-left: 2.86rem;
            @include get-theme.get-theme(border-bottom, white-primary, 1px, solid);
            size: 1rem;
            line-height: 1.71rem;
            @include get-theme.get-theme(color, black-text);
  
            &.action-column {
              text-align: left;
              .action-icon {
                width: 1.14rem;
                height: 1.14rem;
                margin-right: 1.43rem;
                cursor: pointer;
  
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
        }
      }
    }
  }