@use "../../theme/sass/get-theme";

.empty-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex: 1 0 auto;
  //@include get-theme.get-theme(background-color, background-primary);
}

.empty-message-image {
  margin-bottom: 1.14rem;
  width: 7.43rem;
  height: 7.43rem;
}

.empty-message-text {
  font-size: 1.14rem;
  @include get-theme.get-theme(color, light-grey);
  line-height: 1.71rem;
}
