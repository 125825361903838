@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

@mixin font-styling {
  line-height: 1.5;
}

.login-main-container {
  height: 100vh;
  width: 100vw;
  @include get-theme.get-theme(background-color, input-background);
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-wrapper {
  width: 76%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1rem;
  @include get-theme.get-theme(background-color, white-primary);
  position: relative;
}

.logo-with-labels-wrapper-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.logo-with-labels-wrapper {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.logo {
  height: 3.75rem;
  width: 19.21rem;
}

.login-text {
  font-family: "MichelinUnitTitling-Bold";
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5;
  @include font-styling;
  @include get-theme.get-theme(color, text-dark);
}

.welcome-text {
  font-size: 1.3rem;
  @include font-styling;
  font-weight: normal;
  @include get-theme.get-theme(color, grey-shade-two);
}

.connect-with-id-text {
  font-size: 1.3rem;
  @include font-styling;
  font-weight: normal;
  @include get-theme.get-theme(color, grey-shade-two);
}

.connect-with-id-button {
  @include button-theme.button-theme;
  width: 22.75rem;
  height: 4rem;
  font-size: 1.3rem;
}
.login-app-detail-heading {
  margin-top: 12%;
  margin-bottom: 2rem;
  @include get-theme.get-theme(color, text-grey);
  font-weight: bold;
  font-size: 1.5rem;
  @include font-styling;
}
.login-page-app-deatil-text {
  margin-top: 1.3rem;
  font-size: 1.3rem;
  @include font-styling;
  @include get-theme.get-theme(color, grey-shade-two);
}

.login-image {
  height: 100%;
  width: 55%;
}

.div-pose {
  bottom: -90px;
  right: -200px;
  position: absolute;
}

@media screen and (max-width: 1680px) {
  .logo-with-labels-wrapper {
    gap: 2rem;
  }
  .logo {
    margin-right: 40px;
    height: 3rem;
    width: 13.2rem;
  }

  .login-text {
    font-size: 1.3rem;
  }

  .welcome-text {
    font-size: 1.2rem;
  }

  .connect-with-id-text {
    font-size: 1.2rem;
  }
  .login-app-detail-heading {
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }

  .login-page-app-deatil-text {
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .connect-with-id-button {
    @include button-theme.button-theme;

    width: 18.2rem;
    height: 4rem;
    font-size: 1.2rem;
  }
  .div-pose {
    bottom: -85px;
    right: -120px;
  }
}

@media screen and (max-width: 1441px) {
  .logo-with-labels-wrapper {
    gap: 1.5rem;
  }
  .logo {
    margin-right: 40px;
    height: 2.7rem;
    width: 12.2rem;
  }

  .login-text {
    font-size: 1.2rem;
  }

  .welcome-text {
    font-size: 1.2rem;
  }

  .connect-with-id-text {
    font-size: 1.2rem;
  }
  .login-app-detail-heading {
    margin-bottom: 1.5rem;
    font-size: 1.3rem;
  }

  .login-page-app-deatil-text {
    margin-top: 0.8rem;
    font-size: 1.2rem;
  }

  .connect-with-id-button {
    @include button-theme.button-theme;

    width: 18.2rem;
    height: 4rem;
    font-size: 1.2rem;
  }

  .div-pose {
    bottom: -90px;
    right: -120px;
  }
}

@media screen and (max-width: 1280px) {
  .logo-with-labels-wrapper {
    gap: 2rem;
  }
  .logo {
    margin-right: 40px;
    height: 2.3rem;
    width: 11.83rem;
  }

  .login-text {
    font-size: 1rem;
  }

  .welcome-text {
    font-size: 1rem;
  }

  .connect-with-id-text {
    font-size: 1rem;
  }
  .login-app-detail-heading {
    margin-bottom: 1.3rem;
    font-size: 1.2rem;
  }

  .login-page-app-deatil-text {
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .connect-with-id-button {
    @include button-theme.button-theme;

    width: 18rem;
    height: 4rem;
    font-size: 1rem;
  }

  .div-pose {
    bottom: -56px;
    right: -115px;
  }
}

@media screen and (max-width: 1024px) {
  .logo-with-labels-wrapper {
    gap: 2rem;
  }
  .logo {
    margin-right: 40px;
    height: 2.3rem;
    width: 11.83rem;
  }

  .login-text {
    font-size: 1rem;
  }

  .welcome-text {
    font-size: 1rem;
  }

  .connect-with-id-text {
    font-size: 1rem;
  }
  .login-app-detail-heading {
    margin-bottom: 1.3rem;
    font-size: 1.1rem;
  }

  .login-page-app-deatil-text {
    margin-top: 0.5rem;
    font-size: 1rem;
  }

  .connect-with-id-button {
    @include button-theme.button-theme;
    width: 15rem;
    height: 3rem;
    font-size: 0.8rem;
  }

  .div-pose {
    bottom: -56px;
    right: -115px;
  }
}
