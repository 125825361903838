@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.developer-portal-container {
  @include get-theme.get-theme(
    background-color,
    input-background-shade-with-alpha
  );

  .banner-container {
    position: relative;
    text-align: left;
    margin-bottom: 2rem;

    .banner-image {
      width: 100%;
      height: auto;
    }
  }

  .content-container {
    @include get-theme.get-theme(background-color, white-primary);
    border-radius: 0.29rem;
    margin: 0 10%;
    margin-top: 5.5rem;
    margin-bottom: 2.85rem;
    text-align: center left;
    padding: 2.29rem;
    padding-top: 1.14rem;

    // Adjust margin-top for specific screen sizes
    @media (max-width: 1920px) {
      margin-top: 5.57rem;
    }

    @media (max-width: 1280px) {
      margin-top: 4rem;
    }

    .description {
      margin-bottom: 1.71rem;
      line-height: 1.71rem;
      font-size: 1.14rem;
      @include get-theme.get-theme(color, deep-black-text);
    }

    .images-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2.28rem;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
