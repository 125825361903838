@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.app-count-flex-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 2.8rem;
}

.bold {
  font-weight: bold;
}

.count-box {
  width: 110px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  @include get-theme.get-theme(background-color, white-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 1px;
}

.count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blue {
  @include get-theme.get-theme(background-color, blue-primary);
}

.green {
  @include get-theme.get-theme(background-color, box-green);
}

.grey {
  @include get-theme.get-theme(background-color, box-grey);
}

.icon-logo {
  width: 2rem;
  height: 2rem;
}

.count-text {
  @include get-theme.get-theme(color, blue-primary);
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 10px;
}

.app-count-title-text {
  font-size: 0.9rem;
  @include get-theme.get-theme(color, white-text);
  text-align: center;
  font-family: "MichelinUnitText";
  font-style: italic;
}

.app-count-text {
  font-size: 2.57rem;
  @include get-theme.get-theme(color, white-primary);
  margin-bottom: 10px;
  font-family: "MichelinUnitText-Bold-Italic";
}

.box-border-radius {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

@media screen and (max-width: 1280px) {
  .app-count-text {
    font-size: 1.5rem;
  }
  .icon-logo {
    width: 1.25rem;
    height: 1.25rem;
  }
  .count-box {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 1090px) {
  .count-container {
    flex-direction: column;
  }

  .flex-row {
    margin-bottom: 10px;
  }
}
