@use "../../theme/sass/get-theme";

.progress-bar-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .progress-bar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

    progress {
      appearance: none;
      height: 0.7rem;
      border-radius: 7.5px;
      @include get-theme.get-theme(color, white-text);
      margin-left: 0.57rem;
      width: 90%;
      /* Chrome and Safari */
      &::-webkit-progress-bar {
        @include get-theme.get-theme(background-color, white-text);
        border-radius: 7.5px;
      }

      &::-webkit-progress-value {
        @include get-theme.get-theme(background-color, blue-primary);
        border-radius: 7.5px;
      }

      /* Firefox */
      &::-moz-progress-bar {
        @include get-theme.get-theme(background-color, background-primary);
        border-radius: 7.5px;
      }
    }

    label {
      @include get-theme.get-theme(color, description-text);
      font-size: 0.86rem;
      line-height: 1.5;
    }
  }
}
