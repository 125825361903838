@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.analytics-home-screen-container {
  display: flex;
  align-items: center;
  margin: 0 10%;
  margin-top: 1.71rem;
  gap: 1.71rem;
}

.app-analytics-heading-home-screen {
  font-size: 1.42rem;
  font-weight: bold;
  line-height: 2.14rem;
  @include get-theme.get-theme(color, text-dark);
  margin: 0 10%;
  margin-top: 1.71rem;
}

.analytics-categories {
  text-align: center;
  padding: 1.14rem 1.14rem 1.71rem;
  border-radius: 0.28rem;
  box-shadow: 0 0 0.71rem 0 rgba(0, 0, 0, 0.15);
  @include get-theme.get-theme(background-color, white-primary);
}

.analytics-categories-label {
  margin-top: 1.71rem;
  font-size: 1.28rem;
  font-weight: bold;
  line-height: 1.92rem;
  @include get-theme.get-theme(color, text-dark-grey);
}

.analytics-categories-description {
  margin-top: 0.57rem;
  margin-bottom: 1.71rem;
  font-size: 1rem;
  line-height: 1.71rem;
  @include get-theme.get-theme(color, text-grey);
}

.start-analytics-button {
  height: 3.42rem;
  padding: 0.85rem 2.28rem;
  border-radius: 0.28rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  @include get-theme.get-theme(background-color, blue-primary);
  font-size: 1.14rem;
  line-height: 1.71rem;
  @include get-theme.get-theme(color, white-primary);
}

@media screen and (max-width: 1680px) {
  .analytics-images {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 1280px) {
  .app-analytics-heading-home-screen {
    font-size: 1.14rem;
    line-height: 1.71rem;
  }

  .analytics-categories {
    padding: 1.14rem;
  }

  .analytics-categories-description {
    margin-bottom: 1.14rem;
    line-height: 1.28rem;
    font-size: 0.85rem;
  }

  .analytics-categories-label {
    font-size: 1.14rem;
    margin-top: 1.14rem;
  }
}
