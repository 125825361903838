@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.processing-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.processing-dialog {
  @include get-theme.get-theme(background-color, white-primary);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.img {
  max-width: 100%;
  height: 7.14rem;
  object-fit: cover;
}
