@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.error-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.error-popup {
  max-width: 50%;
  width: 50%;
  margin: 0 5%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 0.85rem;
  overflow: hidden;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
}

.error-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  @include get-theme.get-theme(background-color, text-red);
  @include get-theme.get-theme(color, white-primary);

  h2 {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    font-size: 1.71rem;
  }

  .error-popup-close {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    img {
      width: 1.7rem;
      height: 1.7rem;
    }
  }
}

.error-popup-body {
  padding: 2.8rem;
  @include get-theme.get-theme(background-color, white-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-popup-text {
  text-align: center;

  strong {
    @include get-theme.get-theme(color, text-dark);
    display: block;
    margin-bottom: 1.1rem;
    font-size: 1.14rem;
  }

  p {
    @include get-theme.get-theme(color, text-dark);
    margin: 0;
    font-size: 1rem;
  }
}
