@use "../../../theme/sass/get-theme";

.create-persona-heading {
  margin-top: 1.714rem;
}

.create-persona-sub-heading {
  font-size: 1.429rem;
  font-weight: bold;
  line-height: 2.143rem;
  margin-bottom: 2.143rem;
  @include get-theme.get-theme(color, text-dark);
}

.create-persona-select-business-line {
  font-size: 1.286rem;
  font-weight: bold;
  line-height: 1.929rem;
  margin-bottom: 0.571rem;
  @include get-theme.get-theme(color, blue-primary);
}

.create-persona-pick-business-line-label {
  line-height: 1.5rem;
  @include get-theme.get-theme(color, grey-shade-four);
  margin-bottom: 1.143rem;
}

.create-persona-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.create-persona-sub-container {
  display: inline-block;
  @include get-theme.get-theme(background-color, white-primary);
  margin: 1.714rem 14.571rem 4rem 14.571rem;
  border-radius: 1.143rem;
  width: 100%;
}

.create-persona-container-padding {
  padding: 2.286rem 4.571rem 4rem 2.286rem;
}

.stepper-wrapper {
  margin-left: 1.714rem;
}

.StepProgress {
  position: relative;
  padding-left: 3.214rem;
  list-style: none;
}

.StepProgress::before {
  display: inline-block;
  content: "";

  position: absolute;
  top: 0;
  left: 1.071rem;
  width: 0.714rem;
  height: 100%;
  @include get-theme.get-theme(border-left, grey-shade-four, 1px, solid);
}

.StepProgress-item {
  position: relative;
  counter-increment: list;
  padding-left: 1.714rem;
}

.StepProgress-item:not(:last-child) {
  padding-bottom: 3.429rem;
}

.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -2.143rem;
  height: 100%;
  width: 0.714rem;
}

.StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -3.286rem;
  width: 1.429rem;
  height: 1.429rem;
  padding: 0.357rem;
  @include get-theme.get-theme(border, grey-shade-four, 1px, solid);
  border-radius: 5.714rem;
  @include get-theme.get-theme(background-color, white-primary);
}

.StepProgress-item.is-done::before {
  @include get-theme.get-theme(border-left, blue-primary, 1px, solid);
  height: 100%;
}

.StepProgress-item.is-done::after {
  background: url("../../../assets/images/check_mark.png") center center
    no-repeat;
  @include get-theme.get-theme(color, white-primary);
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  @include get-theme.get-theme(background-color, blue-primary);
}

.StepProgress-item.current::before {
  @include get-theme.get-theme(border-left, blue-primary, 1px, solid);
}

.StepProgress-item.current::after {
  display: inline-block;
  background: url("../../../assets/images/blue_filled_circle.png") center center
    no-repeat;
  position: absolute;
  top: 0;
  left: -3.286rem;
  width: 1.429rem;
  height: 1.429rem;
  padding: 0.357rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  border-radius: 5.714rem;
  @include get-theme.get-theme(background-color, white-primary);
}

.StepProgress strong {
  display: block;
}

.create-persona-search-div-wrapper {
  background-color: #f8f8f8;
  border-radius: 0.286rem;
  width: 34.857rem;
  margin-bottom: 2.286rem;
}

.create-persona-search-box {
  width: 32.5rem;
  height: 3.429rem;
  border: none;
  padding-left: 1.143rem;
  background: url(../../../assets/images/search.png) no-repeat 100%;
  background-size: 1rem;
}

.create-persona-business-lines-capsule-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2.286rem;
}

.persona-business-lines-capsule {
  display: flex;
  align-items: center;
  height: 2.571rem;
  padding: 0.429rem 2.286rem;
  border-radius: 5.714rem;
  box-shadow: 0 0.071rem 0.214rem 0 rgba(39, 80, 155, 0.1);
  @include get-theme.get-theme(background-color, white-shade-one);
  line-height: 1.714rem;
}

.persona-business-category-capsule {
  display: flex;
  align-items: center;
  height: 2.571rem;
  padding: 0.429rem 2.286rem;
  border-radius: 5.714rem;
  box-shadow: 0 0.071rem 0.214rem 0 rgba(39, 80, 155, 0.1);
  @include get-theme.get-theme(background-color, white-shade-one);
  line-height: 1.714rem;
}

.create-persona-new-category-remove {
  width: 0.857rem;
  height: 0.857rem;
  padding-left: 1.143rem;
}

.create-persona-business-lines-capsule-border {
  font-size: 1.143rem;
  text-decoration: underline;
  box-shadow: none;
  @include get-theme.get-theme(background-color, white-primary);
  padding: 0.429rem 0rem;
  @include get-theme.get-theme(color, blue-primary);
}

.create-persona-new-category {
  display: flex;
}

.create-persona-add-new-category-heading {
  margin-top: 1.714rem;
}

.persona-business-line-capsule-color {
  @include get-theme.get-theme(background-color, blue-primary);
  @include get-theme.get-theme(color, white-primary);
}

.create-persona-select-business-category {
  font-size: 1.286rem;
  font-weight: bold;
  line-height: 1.929rem;
  margin-bottom: 2.286rem;
  @include get-theme.get-theme(color, blue-primary);
}

.create-persona-label-disable {
  @include get-theme.get-theme(color, grey-shade-four);
}

.create-persona-section-hide {
  display: none;
}

.plus-symbol {
  width: 1.143rem;
  height: 1.143rem;
  margin-left: 0.571rem;
}

.input_container {
  display: flex;
  gap: 2.286rem;
}

.create-persona-check-mark-large {
  width: 1.357rem;
  height: 1.357rem;
}

.create-persona-save-category {
  padding: 0.429rem 0.571rem 0.357rem 0.571rem;
  border-radius: 5.714rem;
  @include get-theme.get-theme(background-color, blue-primary);
  margin-left: 2.286rem;
  margin-top: 3.714rem;
}

.create-persona-input-category-div {
  width: 48.8%;
}

.create-persona-input-width {
  width: 96%;
}

.create-persona-seperator-line {
  @include get-theme.get-theme(border, border-grey, 1px, solid);
  margin-bottom: 2.286rem;
}

.create-persona-input-field-container {
  width: 48.8%;
  margin-bottom: 1.714rem;
}

.create-persona-input-margin-bottom {
  margin-bottom: 2.214rem;
}

.create-persona-customer-business-type-label {
  margin-bottom: 1.143rem;
}

.create-persona-input-margin-top {
  margin-top: 1.714rem;
}

.create-persona-textarea {
  scrollbar-color: #27509b white;
  outline: none;
  width: 97.8%;
}

.create-persona-buttons {
  text-align: center;
  margin-top: 4.143rem;
}

.create-persona-discard-button {
  padding: 0.857rem 2.286rem;
  border-radius: 0.286rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  background: none;
  @include get-theme.get-theme(color, blue-primary);
}

.create-persona-submit-button {
  padding: 0.857rem 2.286rem;
  border-radius: 0.286rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  @include get-theme.get-theme(background-color, blue-primary);
  @include get-theme.get-theme(color, white-primary);
  margin-left: 2.286rem;
}

.persona-names {
  margin-bottom: 0.71rem;
  @include get-theme.get-theme(color, text-grey);
}

.persona-names-label {
  font-weight: bold;
  margin-right: 0.71rem;
}

@media screen and (max-width: 1680px) {
  .create-persona-input-width {
    width: 95%;
  }
}

@media screen and (max-width: 1280px) {
  .create-persona-container-padding {
    padding: 1.143rem 5.071rem 2.857rem 1.143rem;
  }

  .create-persona-sub-heading {
    font-size: 1.143rem;
    line-height: 1.714rem;
    margin-bottom: 0.929rem;
  }

  .StepProgress-item {
    padding-left: 0.571rem;
  }

  .create-persona-select-business-line {
    font-size: 1rem;
    line-height: 1.714rem;
    margin-bottom: 0.286rem;
  }

  .create-persona-pick-business-line-label {
    font-size: 0.714rem;
    line-height: 0.571rem;
  }

  .persona-business-lines-capsule {
    height: 2.286rem;
    padding: 0.286rem 1.143rem;
    font-size: 0.857rem;
  }

  .StepProgress-item:not(:last-child) {
    padding-bottom: 2.857rem;
  }

  .create-persona-select-business-category {
    font-size: 1rem;
    line-height: 1.714rem;
    margin-bottom: 1.143rem;
  }

  .persona-business-category-capsule {
    height: 2.286rem;
    padding: 0.286rem 1.143rem;
    font-size: 0.857rem;
  }

  .plus-symbol {
    width: 0.857rem;
    height: 0.857rem;
    margin-left: 0.571rem;
  }

  .create-persona-check-mark-large {
    width: 1.071rem;
    height: 1.071rem;
  }

  .StepProgress-item::before {
    left: -2.09rem;
  }

  .StepProgress-item::after {
    left: -3.071rem;
    width: 1.143rem;
    height: 1.143rem;
  }

  .StepProgress-item.current::after {
    left: -3.071rem;
    width: 1.143rem;
    height: 1.143rem;
    padding: 0.357rem;
  }

  .create-persona-input-width {
    width: 93.5%;
  }
}
