@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.myTeam-container {
  display: flex;
  flex-direction: column;
  @include get-theme.get-theme(background-color, white-primary);
  flex: 1 0 auto;
  margin-bottom: 1.5rem;
  margin-top: 1.14rem;
  padding: 0 1.5rem;
}

.create-team-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 1.5rem 0 1.5rem;
  font-size: 1.43rem;
  line-height: 2.14rem;
  @include get-theme.get-theme(color, black-text);
  font-weight: bold;
}

.my-team-count-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1.5rem 0 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  @include get-theme.get-theme(color, light-grey);
}

.create-team-button {
  @include button-theme.secondary-button-theme;
  cursor: pointer;
  font-size: 1.2rem;
}

.create-team-icons {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
}
