@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-popup {
  max-width: 50%;
  width: 50%;
  margin: 0 5%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 0.85rem;
  overflow: hidden;
  box-shadow: 0 0 1.357rem 0 rgba(0, 0, 0, 0.07);
}

.delete-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  @include get-theme.get-theme(background-color, text-red);
  @include get-theme.get-theme(color, white-primary);

  .delete-popup-header-text {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    font-size: 1.71rem;
    line-height: 2.571rem;
    font-weight: bold;
  }
}

.delete-popup-body {
  padding: 2.8rem;
  @include get-theme.get-theme(background-color, white-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.delete-popup-text {
  text-align: center;

  strong {
    @include get-theme.get-theme(color, text-dark);
    display: block;
    margin-bottom: 1.1rem;
    font-size: 1.14rem;
  }

  p {
    @include get-theme.get-theme(color, text-dark);
    margin: 0;
    font-size: 1rem;
  }

  .delete-popup-buttons {
    margin-top: 2.286rem;

    button {
      padding: 0.857rem 2.286rem;
      border-radius: 0.286rem;
      cursor: pointer;
      font-size: 1rem;
    }

    .delete-popup-cancel-button {
      @include get-theme.get-theme(color, blue-primary);
      @include get-theme.get-theme(border, blue-primary, 1px, solid);
      @include get-theme.get-theme(background-color, white-primary);
      margin-right: 1.714rem;
    }

    .delete-popup-delete-button {
      @include get-theme.get-theme(color, white-primary);
      @include get-theme.get-theme(border, blue-primary, 1px, solid);
      @include get-theme.get-theme(background-color, blue-primary);
    }
  }
}

@media screen and (max-width: 1280px) {
  .delete-popup-header {
    .delete-popup-header-text {
      font-size: 1.429rem;
      line-height: 2.143rem;
    }
  }

  .delete-popup-body {
    padding: 1.714rem;
  }

  .delete-popup-text {
    strong {
      margin-bottom: 0.286rem;
      font-size: 1rem;
    }

    p {
      font-size: 0.857rem;
    }

    .delete-popup-buttons {
      margin-top: 1.714rem;

      button {
        padding: 0.571rem 1.143rem;
      }
    }
  }
}
