@use "../../theme/sass/get-theme";

.edit-persona-heading {
  margin-top: 1.714rem;
}

.edit-persona-container-padding {
  padding: 2.286rem 3.429rem 2.857rem 2.286rem;
}

.edit-persona-grid-container {
  display: grid;
  grid-template-columns: 30% 30% 30% 30%;
}

.edit-persona-persona-name-container {
  display: grid;
  grid-template-columns: 60% 50%;
}

.edit-persona-persona-name-container > div:nth-child(2) > .title-field {
  margin-bottom: 0rem;
}

.edit-persona-capsule-container {
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 20%;
}

.edit-persona-email-container {
  display: grid;
  grid-template-columns: 30% 30%;
  column-gap: 30%;
  margin-top: 1.714rem;
}

.edit-persona-textarea {
  scrollbar-color: #27509b white;
  outline: none;
  box-sizing: border-box;
  width: 100%;
  min-height: 10.571rem;
}

.edit-persona-dropdown > .input-field {
  height: auto;
  outline: none;
  width: auto;
  appearance: none;
  background-image: url("../../assets/images/drop_down.png");
  background-repeat: no-repeat;
  background-position: 92%;
  background-size: 1.143rem 1.143rem;
}

.edit-persona-edit-icon {
  margin-left: 4.571rem;
  margin-top: 3.214rem;
  height: 1.143rem;
  width: 1.143rem;
}

.edit-persona-container-padding > div > div > .title-field {
  margin-top: 0rem;
}

.edit-persona-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10%;
}

.edit-persona-sub-container {
  display: inline-block;
  @include get-theme.get-theme(background-color, white-primary);
  margin: 1.714rem 14.571rem 4rem 14.571rem;
  border-radius: 1.143rem;
  width: 100%;
}

.persona-business-line-capsule-color {
  @include get-theme.get-theme(background-color, blue-primary);
  @include get-theme.get-theme(color, white-primary);
}

.edit-persona-seperator-line {
  @include get-theme.get-theme(border, border-grey, 1px, solid);
  margin-top: 2.321rem;
  margin-bottom: 2.321rem;
}

.edit-persona-input-field-container {
  width: 31.6%;
  margin-bottom: 1.714rem;
}

.edit-persona-buttons {
  text-align: right;
  margin-top: 4.143rem;
}

.edit-persona-discard-button {
  padding: 0.857rem 2.286rem;
  border-radius: 0.286rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  background: none;
  @include get-theme.get-theme(color, blue-primary);
}

.edit-persona-submit-button {
  padding: 0.857rem 2.286rem;
  border-radius: 0.286rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  @include get-theme.get-theme(background-color, blue-primary);
  @include get-theme.get-theme(color, white-primary);
  margin-left: 2.286rem;
}

@media screen and (max-width: 1280px) {
  .persona-business-lines-capsule {
    height: 2.286rem;
    padding: 0.286rem 1.143rem;
    font-size: 0.857rem;
  }
}
