@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.app-card {
  display: flex;
  align-items: center;
  border-radius: 4px;
  @include get-theme.get-theme(background-color, white-primary);
  padding: 1.5rem;
  overflow: hidden;
}

.app-card-content {
  display: flex;
  width: 100%;
}

.app-card-icon {
  width: 6.78rem;
  height: 6.78rem;
  border-radius: 1.07rem;
  border: 1px solid #ffffff;
  object-fit: contain;
}

.app-card-icon img {
  height: 100%;
  border-radius: 4px;
}

.app-card-details {
  flex-grow: 1;
}

.app-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.dashboard-app-name {
  margin: 0 auto 0.57rem 1.14rem;
  font-size: 1rem;
  line-height: 1.5;
  @include get-theme.get-theme(color, card-name-text);
  font-family: NotoSans;
}

.store-icon {
  margin-left: 0.57rem;
  width: 1.14rem;
  height: 1.14rem;
  align-self: top;
}

.app-card-text {
  line-height: 1.28rem;
  margin: 0.57rem auto 0.57rem 1.14rem;
  font-size: 0.85rem;
  text-wrap: wrap;
  @include get-theme.get-theme(color, light-grey);
  font-family: NotoSans;
}

.app-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.14rem;
}

.app-card-footer-details {
  display: flex;
  flex-direction: column;
}

.app-status-text {
  line-height: 1.5;
  font-size: 1rem;
  @include get-theme.get-theme(color, light-grey);
  font-family: NotoSans;
}

.app-rating {
  font-size: 0.85rem;
  line-height: 1.28rem;
  @include get-theme.get-theme(color, text-grey);
  display: flex;
  align-items: center;
  font-family: NotoSans;
  margin: 4px;
}

.app-rating span {
  margin-right: 4px;
}

.star-icon {
  width: 0.57rem;
  height: 0.57rem;
}

.app-register-button {
  width: auto;
  padding: 0.42rem 2.28rem;
  border-radius: 0.42rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.71rem;
  text-align: center;
  @include get-theme.get-theme(color, blue-primary);
  @include get-theme.get-theme(background-color, white-primary);
  align-self: flex-end;
  font-family: NotoSans;
}

.text-dark {
  @include get-theme.get-theme(color, text-dark);
}

.dropdown-container {
  position: relative;

  .dropdown-menu {
    display: block;
    position: absolute;
    top: calc(100% + 5px);
    /* Adjust based on icon size */
    right: 0;
    background-color: white;
    box-shadow: 0 0.57rem 1.14rem rgba(0, 0, 0, 0.2);
    z-index: 1000;
    min-width: 8.57rem;
    border-radius: 0.29rem;
  }

  .dropdown-item {
    padding: 0.57rem 0.86rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #f1f1f1;
    }

    .dropdown-icon {
      margin-right: 0.57rem;
      width: 1.14rem;
      height: 1.14rem;
    }
  }
}

@media screen and (max-width: 1280px) {
  .app-register-button {
    width: 5rem;
    height: 2rem;
    padding: 0.125rem 0.75rem;
  }

  .app-card-text {
    font-size: 0.75rem;
  }

  .app-status-text {
    font-size: 0.75rem;
  }
}