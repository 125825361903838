@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.success-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.success-popup {
  max-width: 50%;
  width: 50%;
  margin: 0 5%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
}

.success-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  @include get-theme.get-theme(background-color, green);
  @include get-theme.get-theme(color, white-primary);

  h2 {
    margin: 0;
    flex-grow: 1;
    text-align: center;
    size: 1.7rem;
    line-height: 36px;
  }

  .success-popup-close {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.success-popup-body {
  padding: 40px;
  @include get-theme.get-theme(background-color, white-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-popup-text {
  text-align: center;

  strong {
    @include get-theme.get-theme(color, text-dark);
    display: block;
    margin-bottom: 16px;
    font-size: 1.1rem;
  }

  p {
    @include get-theme.get-theme(color, text-dark);
    margin: 0;
    margin-bottom: 16px;
    font-size: 1rem;
    margin-left: 59px;
    margin-right: 59px;
    line-height: 24px;
  }

  .success-popup-buttons {
    display: flex;
    justify-content: center;
    gap: 40px;

    button {
      padding: 12px 32px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 1rem;
    }

    .register-app-button {
      @include get-theme.get-theme(color, blue-primary);
      @include get-theme.get-theme(border, blue-primary, 1px, solid);
      @include get-theme.get-theme(background-color, white-primary);
    }

    .view-apps-button {
      @include get-theme.get-theme(color, white-primary);
      @include get-theme.get-theme(background-color, blue-primary);
      border: none;
    }
  }
}
