@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.tab-component-tab-container {
  height: 3.4rem;
  width: fit-content;
}

/* Style the tab */
.tab {
  overflow: hidden;

  margin: 0 20px 0 0;
  border-radius: 4px;
  height: 100%;

  .last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

.tab-background-white {
  @include get-theme.get-theme(background-color, white-primary);
}

.tab-background-grey {
  @include get-theme.get-theme(background-color, input-background);
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  height: 100%;
  transition: 0.3s;
  padding: 0px 20px;
  font-size: 1.14rem;
  @include get-theme.get-theme(color, blue-primary);
}

/* Change background color of buttons on hover */
.tab button:hover {
  @include get-theme.get-theme(background-color, white-text);
}

/* Create an active/current tablink class */
.tab button.active {
  @include get-theme.get-theme(background-color, blue-primary);
  @include get-theme.get-theme(color, white-primary);
  text-shadow: 0.4px 0px 0px white, -0.4px 0px 0px white;
}

@media screen and (max-width: 610px) {
  .tab button {
    padding: 0px 10px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 410px) {
  .tab button {
    padding: 0px 7px;
    font-size: 0.8rem;
  }
}