@use "../../../theme/sass/get-theme";

.app-analytics-sub-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 2.3rem;
  margin-bottom: 2.3rem;
  max-width: 95%;
  min-width: 60%;
  box-sizing: border-box;
  @include get-theme.get-theme(background-color, white-primary);
}
.app-data-table-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1.75rem;
  box-sizing: border-box;
  width: 100%;
  table {
    border-spacing: 0px 1px;
  }
}

.app-data-sub-table-container {
  overflow: scroll;
  display: flex;
  scrollbar-color: #27509b #b2b2b2;
}

.app-data-table {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex: 1 0 auto;
  overflow: auto;
}

.sub-table {
  text-align: center;

  table {
    tr:nth-child(odd) {
      @include get-theme.get-theme(background-color, table-row-background-odd);
    }

    tr:nth-child(even) {
      @include get-theme.get-theme(background-color, table-row-background-even);
    }
  }

  .sub-table-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5rem;
    font-family: "MichelinUnitTitling-Bold";
    text-align: center;
    @include get-theme.get-theme(
      background-color,
      table-main-header-light-blue
    );
    height: 4.6rem;
    .sub-table-header {
      font-weight: bold;
    }
  }
  .buisness-line-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }
  .column-header {
    height: 4.2rem;
    width: 8.1rem;
    @include get-theme.get-theme(background-color, table-header-light-blue);
    padding: unset;
  }
  .text-grey {
    @include get-theme.get-theme(color, text-grey);
  }
  .data-cell {
    width: 8.1rem;
    height: 4rem;
    margin-top: 0.14rem;
    font-size: 1.14rem;
  }
}

.sub-table-app-list {
  position: sticky;
  flex: 1 0 auto;
  padding: 0 1rem;
  .sub-table-app-list-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 8.5rem;
    width: 100%;

    &-input {
      width: 14.7rem;
      height: 3.4rem;
      @include get-theme.get-theme(border, blue-shade-two, 1px, solid);
      background: url(../../../assets/images/search.png) no-repeat 95%;
      background-size: 1.14rem;
    }
    ::placeholder {
      @include get-theme.get-theme(color, text-grey);
      padding-left: 1.14rem;
      font-size: 1.14rem;
    }
  }

  .data-cell {
    height: 4rem;
    margin-top: 0.14rem;
    font-size: 1.14rem;
    text-wrap: wrap;
  }
}
