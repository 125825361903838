@use "../../theme/sass/get-theme";

.my-apps-heading {
  margin-top: 1.714rem;
  margin-bottom: 1.714rem;
  margin-left: 10%;
  margin-right: 10%;
  font-size: 1.429rem;
  line-height: 2.143rem;
}

.my-apps-container {
  margin: 0 10%;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  margin-bottom: 2.86rem;
}

.my-apps-tabs-options-wrapper {
  display: flex;
  position: static;
}

.my-apps-tabs-label {
  margin-right: 3.214rem;
  padding-bottom: 1.143rem;
  font-size: 1.143rem;
  line-height: 1.714rem;
  text-align: center;
}

.my-apps-tabs-label > a {
  text-decoration: none;
  @include get-theme.get-theme(color, blue-primary);
}

.my-apps-tabs-label-select {
  @include get-theme.get-theme(color, black-text-shade);
}

.no-apps-message {
  flex: 1 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.4rem;
}

.myApps-container {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin: 1.71rem 9rem;
}

.myApps-heading {
  font-size: 1.4rem;
  font-weight: bold;
  @include get-theme.get-theme(color, text-dark);
  margin-bottom: 1.14rem;
  margin-top: 1.714rem;
  margin-left: 0;
}

.myApps-card-container {
  display: grid;
  /* 2 cards per row */
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 2rem;
}

@media only screen and (max-width: 1799px) {
  .myApps-container {
    margin: 0 12rem;
  }
}

@media only screen and (max-width: 1600px) {
  .myApps-container {
    margin: 0 14rem;
  }
}

@media only screen and (max-width: 1400px) {
  .myApps-container {
    margin: 0 4rem;
  }
}
