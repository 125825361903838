@use "../../../theme/sass/get-theme";

.persona-container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.manage-persona-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.persona-manage-label {
  margin-top: 2.357rem;
  font-size: 1.429rem;
  line-height: 2.143rem;
  font-weight: bold;
}

.create-persona-button {
  display: flex;
  align-items: center;
  gap: 1.143rem;
  margin-top: 1.821rem;
  padding: 0.857rem 2.286rem;
  border-radius: 0.286rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  font-size: 1.143rem;
}

.persona-by-status-heading {
  font-size: 1.4rem;
  font-weight: bold;
  @include get-theme.get-theme(color, text-dark);
  margin-top: 1.714rem;
  margin-left: 0;
}

.create-persona-button > div > a {
  text-decoration: none;
  @include get-theme.get-theme(color, blue-primary);
  line-height: 1.714rem;
}

.persona-list-container {
  margin-top: 1.7rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
}
.persona-list-empty-message-container {
  display: flex;
  flex-direction: column;
  align-self: center;
  flex: 1 0 auto;
}

@media screen and (max-width: 1280px) {
  .persona-manage-label {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .persona-by-status-heading {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .create-persona-button {
    font-size: 0.857rem;
  }
}

@media screen and (max-width: 1600px) {
  .persona-manage-label {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }

  .persona-by-status-heading {
    font-size: 1.143rem;
    line-height: 1.714rem;
  }
}
