@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.contact-us-banner {
  margin-bottom: 2.28rem;
}

.contact-us-heading {
  font-size: 1.42rem;
}

.contact-us-form {
  margin: 1.71rem 0;
}

.contact-us-container {
  display: block;
  margin-left: 2.28rem;
  margin-right: auto;
}

.contact-query-options-wrapper {
  display: flex;
  position: static;
}

.line-bottom {
  position: relative;
  @include get-theme.get-theme(border-bottom, grey-shade-three, 1px, solid);
  bottom: 0.17rem;
}

.contact-query-type {
  margin-top: 2.28rem;
  margin-right: 3.42rem;
  padding-bottom: 1rem;
  cursor: pointer;
  font-size: 1.14rem;
  line-height: 1.71rem;
  text-align: center;
  @include get-theme.get-theme(color, blue-primary);
}

.contact-query-type:last-child {
  margin-right: 0;
}

.active-button {
  border-bottom: 0.286rem #27509b solid;
  z-index: 999;
  font-weight: bold;
  @include get-theme.get-theme(color, black-text-shade);
}

.contact-us-title-input {
  box-sizing: border-box;
  width: 70%;
  font-size: 1rem;
  padding: 1.14rem 3.42rem 1.14rem 1.14rem;
  @include get-theme.get-theme(background-color, input-background);
  @include get-theme.get-theme(border, input-background, 1px, solid);
  border-radius: 0.28rem;
  line-height: 1.71rem;
  height: 4rem;
}

.contact-us-title-input:focus {
  line-height: 0;
}

.contact-us-textarea-wrapper {
  margin-top: 2.96rem;
}

.contact-us-textarea-label {
  margin-top: 1.5rem;
}

.contact-us-textarea {
  width: 70%;
  resize: none;
  font-size: 1.14rem;
  line-height: 1.71;
  box-sizing: border-box;
  height: 10.57rem;
  border-radius: 0.28rem;
  border: none;
  outline: none;
  @include get-theme.get-theme(background-color, input-background);
  font-family: "Noto Sans";
}

.contact-us-textarea:focus {
  outline: none;
}

.submit-button-wrapper {
  margin-top: 3.57rem;
  margin-left: auto;
  margin-right: auto;
}

.submit-button-contact-us {
  width: 10.5rem;
  height: 3.42rem;
  margin-left: 2.28rem;
  line-height: 1.71rem;
}

@media screen and (max-width: 1280px) {
  .contact-us-heading {
    font-size: 1.14rem;
  }
  .contact-us-form {
    margin: 1.14rem 0;
    .contact-us-form-padding {
      padding: 1.14rem 1.71rem 2.85rem 1.71rem;
    }
  }
  .contact-query-type {
    font-size: 1rem;
    padding-bottom: 0.57rem;
    margin-right: 2.28rem;
  }
  .contact-us-textarea-wrapper {
    margin-top: 1.14rem;
  }
  .contact-us-input-fields-labels {
    margin-top: 1.14rem;
  }
  .submit-button-wrapper {
    margin-top: 3rem;
  }
}
