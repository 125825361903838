@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.review-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.review-popup {
  width: 60%;
  height: 80%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
}
.review-popup-header {
  padding: 2rem;
  @include get-theme.get-theme(background-color, background-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.review-popup-header-content {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  align-self: center;
}
.app-heading-deatils {
  display: flex;
  flex-direction: row;
  gap: 1.1rem;
}
.app-review-heading {
  font-size: 1.5rem;
  font-weight: bold;
  @include get-theme.get-theme(color, text-dark);
}
.app-review-sub-heading {
  font-size: 0.8rem;
  @include get-theme.get-theme(color, description-text);
}
.close-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.reviews-tab-container {
  width: 90%;
  margin-top: 1.7rem;
  align-self: center;
  margin-bottom: 1.1rem;
}
.reviews-container {
  overflow-y: scroll;
  scrollbar-color: #27509b #f2f2f2;
  // @include get-theme.get-theme(scrollbar-color, blue pink);
  display: flex;
  flex-direction: column;
}
.review-card {
  width: 90%;
  align-self: center;
}
.loading-text {
  font-size: 1.2rem;
  font-weight: bold;
  @include get-theme.get-theme(color, blue-primary);
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}
