@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

/* Style the tab */
.main-capsule-container {
  width: 98.8%;
  @include get-theme.get-theme(background-color, input-background);
  @include get-theme.get-theme(border, input-background, 1px, solid);
  padding: 1rem 1rem 0 1rem;
  border-radius: 0.28rem;

  .text-style {
    @include get-theme.get-theme(color, capsule-text);
    margin-bottom: 1rem;
  }

  .capsule-container {
    display: flex;
    height: fit-content;
    flex-wrap: wrap;

    .seleted-container {
      width: 100%;
      @include get-theme.get-theme(border-bottom, grey-border, 1px, solid);
      margin: 0 0 1.1rem;
      display: flex;
      flex-wrap: wrap;

      .close-icon {
        cursor: pointer;
        width: 0.85rem;
        height: 0.85rem;
        margin-left: 1rem;
      }

      button {
        padding: 0.43rem 1.14rem;
      }

      .clear-button {
        align-self: center;
        margin: 0 0 1.1rem auto;
        @include get-theme.get-theme(color, blue-primary);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    button {
      width: auto;
      margin: 0 1rem 1.14rem 0;
      padding: 0.43rem 2.2rem;
      border-radius: 5.7rem;
      @include get-theme.get-theme(border, grey-border, 1px, solid);
      @include get-theme.get-theme(color, capsule-text);
      font-size: 1rem;
      line-height: 1.71;
      text-align: center;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    /* Change background color of buttons on hover */
    button:hover {
      @include get-theme.get-theme(background-color, white-text);
    }

    /* Create an active/current tablink class */
    button.active {
      @include get-theme.get-theme(background-color, blue-primary);
      @include get-theme.get-theme(color, white-primary);
      @include get-theme.get-theme(border, blue-primary, 1px, solid);
      cursor: default;
    }
  }
}
.chosen {
  @include get-theme.get-theme(background-color, light-blue);
}

@media screen and (max-width: 610px) {
  .main-capsule-container {
    .capsule-container {
      button {
        padding: 0px 10px;
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .main-capsule-container {
    .capsule-container {
      button {
        padding: 0px 7px;
        font-size: 0.8rem;
      }
    }
  }
}
