.stars-container {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;

  img {
    width: 0.86rem;
    height: 0.86rem;
  }
}
