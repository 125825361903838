@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.dashboard-sub-container {
  display: flex;
  flex-direction: column;
  margin: 2.3rem 10%;
  position: relative;
}

.dashboard-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.bold {
  font-weight: bold;
}

.title-text-dark {
  font-size: 1.4rem;
  @include get-theme.get-theme(color, text-dark);
  margin-bottom: 1.7rem;
}

.count-box {
  width: 6.875rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  @include get-theme.get-theme(background-color, white-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.313rem 0rem;
}

.count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.blue {
  @include get-theme.get-theme(background-color, blue-primary);
}

.green {
  @include get-theme.get-theme(background-color, box-green);
}

.grey {
  @include get-theme.get-theme(background-color, box-grey);
}

.icon-logo {
  width: 2rem;
  height: 2rem;
}

.count-text {
  @include get-theme.get-theme(color, blue-primary);
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-top: 0.625rem;
}

.dashboard-tabs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-div-wrapper {
  width: 30%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 0.25rem;
}

.search-box {
  width: 90%;
  height: 2rem;
  padding: 0.75rem 0.9rem;
  @include get-theme.get-theme(background-color, white-primary);
  @include get-theme.get-theme(border, white-primary, 1px, solid);
  border-radius: 0.25rem;
  font-size: 1rem;
  background: url(../../assets/images/search.png) no-repeat 100%;
  background-size: 1rem;
}

.search-dropdown {
  width: 30%;
  height: 13.42rem;
  border-radius: 0.25rem;
  overflow-y: scroll;
  scroll-behavior: auto;
  scrollbar-color: #27509b white;
  @include get-theme.get-theme(background-color, input-background);
  position: absolute;
  display: block;
  z-index: 999;
}

.searched-values {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.71;
  @include get-theme.get-theme(color, text-dark);
}

.searched-value {
  padding-left: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

.search-dropdown > .searched-values > div:hover {
  @include get-theme.get-theme(background-color, white-text);
}

input:focus {
  outline: none;
  line-height: 1.5;
}

input::placeholder {
  @include get-theme.get-theme(color, text-grey);
}

@media screen and (max-width: 1920px) {
  .search-box {
    width: 91%;
    background-size: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .search-box {
    background: url(../../assets/images/search.png) no-repeat 97%;
    background-size: 1rem;
    width: 90%;
  }
}

@media screen and (max-width: 1200px) {
  .sub-container {
    margin: 2.3rem 5%;
  }
}

@media screen and (max-width: 1150px) {
  .sub-container {
    margin: 2.3rem 2%;
  }
}

@media screen and (max-width: 930px) {
  .tabs-row {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
}
