@use "../../../../theme/sass/get-theme";

.app-review-box {
  height: fit-content;
  display: flex;
  flex-direction: column;

  .main-review-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.8rem;

    .bold-text {
      width: 20%;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: center;
      @include get-theme.get-theme(color, black-text);
      margin-right: 2.86rem;
    }

    img {
      width: 0.86rem;
      height: 0.86rem;
      margin-right: 0.125rem;
    }

    p {
      font-size: 0.86rem;
      @include get-theme.get-theme(color, description-text);
      margin-top: 0.5rem;
      font-weight: normal;
    }
  }

  .reviews-box {
    width: 100%;
    margin-bottom: 1.7rem;
  }

  .view-all-reviews {
    @include get-theme.get-theme(color, blue-primary);
    font-size: 1rem;
  }
}

.app-reviews-heading-styling {
  margin-bottom: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

@media screen and (max-width: 1280px) {
  .app-review-box {
    width: 37.5rem;
  }
}
