@use "../../../theme/sass/get-theme";

.app-secret-meta-data-sub-container {
  margin-bottom: 2rem;
}

.app-secret-details {
  display: flex;
  border-radius: 0.25rem;
  @include get-theme.get-theme(background-color, pure-white);
  justify-content: space-between;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.app-secrets-label {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

.app-id-secret {
  display: flex;
  justify-content: space-between;
  border-radius: 0.25rem;
  @include get-theme.get-theme(background-color, dark-grey);
  padding: 1.5rem;
}

.app-id-secret > * {
  margin-bottom: 1rem;
}

.app-id-secret:last-child {
  margin-bottom: 0rem;
}

.app-meta-data-labels {
  font-weight: bold;
  line-height: 1.71;
  @include get-theme.get-theme(color, black-text);
}

.app-meta-data-values {
  line-height: 1.29;
  color: #666;
}

@media screen and (max-width: 1280px) {
  .app-secret-meta-data-sub-container {
    width: 100%;
  }
}
