@use "../../../theme/sass/get-theme";

.registrar-details-wrapper {
  width: 100%;
}

.registrar-heading-styling {
  margin-top: 5%;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

.registrar-details-sub-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 0.25rem;
  @include get-theme.get-theme(background-color, dark-grey);
  padding: 1.5rem;
  gap: 1rem;
}

.registrar-details-label {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, deep-black-text);
}

.registrar-details-value {
  line-height: 1.29;
  letter-spacing: normal;
  @include get-theme.get-theme(color, grey-shade-two);
  margin-top: 0.5rem;
}

@media screen and (max-width: 1440px) {
  .registrar-details-sub-container {
    flex-direction: column;
    align-items: start;
  }
}

@media screen and (max-width: 1280px) {
  .registrar-details-sub-container {
    flex-direction: column;
    align-items: start;
  }
}
