@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.assign-apps-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.assign-apps-popup {
  width: 50%;
  max-height: 70%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 0.857rem;
  overflow: hidden;
  box-shadow: 0 0 1.357rem 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
}
.assign-apps-popup-header {
  padding: 1.286rem 2.857rem 1.286rem 2.857rem;
  @include get-theme.get-theme(background-color, background-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
  align-items: center;
}

.assign-apps-heading {
  font-size: 1.714rem;
  line-height: 2.571rem;
  font-weight: bold;
  @include get-theme.get-theme(color, text-dark);
}

.assign-apps-close-icon {
  width: 1.714rem;
  height: 1.714rem;
}

.assign-apps-count-text {
  display: flex;
}

.assign-apps-store-icon {
  width: 4.143rem;
  height: 1.143rem;
}

.assign-apps-popup-content {
  flex-grow: 1;
  display: flex;
  padding: 1.714rem 0rem 0.643rem 2.857rem;
  font-size: 0.857rem;
  line-height: 1.714rem;
  @include get-theme.get-theme(color, grey-shade-four);
  .assign-apps-unselect-text {
    padding-left: 0.571rem;
    @include get-theme.get-theme(color, blue-primary);
    text-decoration: underline;
  }
}

.assign-apps-input-subcontainer input[type="checkbox"] {
  margin-top: 0.857rem;
  margin-left: 0rem;
}

.assign-apps-icon-logo > img {
  width: 2.857rem;
  height: 2.857rem;
  border-radius: 2rem;
}

.assign-apps-app-name {
  font-weight: bold;
  font-size: 1.143rem;
  line-height: 1.714rem;
  margin-bottom: 0.571rem;
  @include get-theme.get-theme(color, text-dark-grey);
}

.assign-apps-app-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
  line-height: 1.714rem;
  @include get-theme.get-theme(color, text-grey);
}

.assign-apps-input-container {
  overflow-y: auto;
  scrollbar-color: #27509b white;
}

.assign-apps-input-subcontainer {
  margin: 1.357rem 2.857rem 0rem 2.857rem;
  overflow-y: auto;
  gap: 1.714rem;
  display: flex;
  flex-direction: row;
}

.assign-apps-input-section-border {
  @include get-theme.get-theme(border, grey-shade-three, 1px, solid);
  margin-top: 3rem;
  margin-left: 2.857rem;
  margin-right: 2.857rem;
}

.assign-apps-icon-description {
  display: flex;
}

.assign-apps-about-app {
  margin-left: 0.571rem;
  margin-top: 0.643rem;
}

.assign-apps-app-usage {
  outline: none;
}

.assign-apps-popup-footer {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.286rem 2.857rem 1.286rem 2.857rem;

  .cancel {
    @include button-theme.secondary-button-theme;
    cursor: pointer;
    width: 6rem;
  }

  .save {
    @include button-theme.button-theme;
    cursor: pointer;
    width: 6rem;
  }
}

.assign-apps-input-field-container {
  margin-left: auto;
}

.assign-apps-input-field-container > .textarea-size {
  display: flex;
  flex-direction: column;
  width: auto;
}

.assign-apps-input-field-container > .title-field {
  margin-top: 0rem;
}

@media screen and (max-width: 1680px) {
  .assign-apps-input-field-container > .textarea-size {
    width: auto;
  }
}

@media screen and (max-width: 1440px) {
  .assign-apps-popup {
    width: 60%;
  }

  .assign-apps-input-field-container > .textarea-size {
    width: auto;
  }
}

@media screen and (max-width: 1280px) {
  .assign-apps-popup {
    width: 60%;
  }

  .assign-apps-heading {
    font-size: 1.429rem;
    line-height: 2.143rem;
  }

  .assign-apps-close-icon {
    width: 1.143rem;
    height: 1.143rem;
  }

  .assign-apps-store-icon {
    width: 3.857rem;
    height: 0.857rem;
  }

  .assign-apps-popup-header {
    padding: 1.143rem 2.286rem 1.143rem 2.286rem;
  }

  .assign-apps-popup-content {
    padding: 1.143rem 0rem 0.571rem 2.286rem;
    font-size: 0.857rem;
    line-height: 1.714rem;
  }
  .assign-apps-input-subcontainer {
    margin: 1.143rem 2.286rem 0rem 2.286rem;
    gap: 1.143rem;
  }

  .assign-apps-input-subcontainer input[type="checkbox"] {
    min-width: 1.143rem;
    height: 1.143rem;
  }

  .assign-apps-icon-logo > img {
    width: 2.286rem;
    height: 2.286rem;
    border-radius: 2rem;
  }

  .assign-apps-about-app {
    margin-top: 0.5rem;
  }

  .assign-apps-app-name {
    font-size: 1rem;
  }

  .assign-apps-app-description {
    font-size: 0.857rem;
    line-height: 1.286rem;
  }

  .assign-apps-input-section-border {
    @include get-theme.get-theme(border, grey-shade-three, 1px, solid);
    margin-top: 1.143rem;
    margin-left: 2.286rem;
    margin-right: 2.286rem;
  }

  .assign-apps-popup-footer {
    padding: 1.143rem 2.286rem 1.143rem 2.286rem;
  }
}
