@use "../../../theme/sass/get-theme";

.countries-heading-styling {
  margin-top: 5%;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

.countries-lines-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @include get-theme.get-theme(background-color, dark-grey);
  padding: 1.5rem;
  position: relative;
  align-items: center;
}

.countries-lines-values {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 5rem;
  border: solid 0.063rem #b2b2b2;
  color: #404040;
  line-height: 1.71;
  text-align: center;
  font-size: 1rem;
}

.div-dropdown-countries {
  width: 11.07rem;
  height: 13.42rem;
  overflow-y: scroll;
  scroll-behavior: auto;
  scrollbar-color: #27509b white;
  @include get-theme.get-theme(background-color, pure-white);
  position: absolute;
  display: block;
  z-index: 999;
}

.show-more-results-countries {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1rem;
  line-height: 1.71rem;
  @include get-theme.get-theme(color, text-dark);
}

.show-more-number-countries {
  padding: 1rem;
  @include get-theme.get-theme(color, blue-shade-one);
  line-height: 1.71rem;
  font-size: 1rem;
  text-align: center;
}
