@use "../../theme/sass/get-theme";

.title-field {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 1.71rem;
  @include get-theme.get-theme(color, text-dark);
  margin-top: 1.21rem;
  margin-bottom: 0.57rem;
  padding: 0;
}

.title-field-disabled-field {
  @include get-theme.get-theme(color, light-grey);
}

.red-text {
  @include get-theme.get-theme(color, text-red);
}

.asterisk {
  vertical-align: super;
}

.textarea-size {
  width: 95.3%;
  display: flex;
  height: 9.25rem;

  padding: 1rem 3rem 1rem 1rem;

  border-radius: 0.28rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  resize: none;
}

.text-area-background {
  @include get-theme.get-theme(background-color, input-background);
  @include get-theme.get-theme(border, input-background, 1px, solid);
}
.text-area-background-white {
  @include get-theme.get-theme(background-color, white-primary);
  @include get-theme.get-theme(border, white-primary, 1px, solid);
}
.div-tooltip-icon {
  margin-top: 0.5rem;
}

.div-tooltip-icon-not-mandatory-fields {
  margin-top: 0.2rem;
}

.input-field {
  width: 90%;
  height: 1rem;
  padding: 1rem 3rem 1rem 1rem;
  @include get-theme.get-theme(background-color, input-background);
  @include get-theme.get-theme(border, input-background, 1px, solid);
  border-radius: 0.28rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.full-width {
  width: 95.3%;
  display: flex;
  height: 1rem;
  padding: 1rem 3rem 1rem 1rem;
  @include get-theme.get-theme(background-color, input-background);
  @include get-theme.get-theme(border, input-background, 1px, solid);
  border-radius: 0.28rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

input[type="checkbox"] {
  appearance: none;
  min-width: 1.429rem;
  height: 1.429rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  border-radius: 0.286rem;
}

input[type="checkbox"]:checked {
  @include get-theme.get-theme(background-color, blue-primary);
  background-image: url("../../assets/images/checkmark_checkbox.png");
  background-position: center center;
}

.validation-msg-container {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  margin-top: 0.6rem;
}
.highlight-input-validation-error {
  border: 1px solid #b71c1c !important;
}
.validation-error {
  font-size: 0.85rem;
  line-height: 1.2rem;
  @include get-theme.get-theme(color, text-red);
}
.validation-icon {
  height: 1.14rem;
  width: 1.14rem;
}

@media only screen and (max-width: 1799px) {
  .textarea-size {
    height: 6rem;
  }
}

@media screen and (max-width: 1280px) {
  .title-field {
    margin-top: 1.14rem;
  }
  input[type="checkbox"] {
    min-width: 1.143rem;
    height: 1.143rem;
  }
}

@media screen and (max-width: 768px) {
  .full-width {
    width: 90%;
  }
}
