@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.pagination-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1.71rem;
}

.arrow-icon {
  padding: 0.43rem;
  width: 1.43rem;
  height: 1.43rem;
  border-radius: 4px;
  cursor: pointer;
  @include get-theme.get-theme(border, border-grey, 1px, solid);
}

.enabled {
  @include get-theme.get-theme(background-color, blue-primary);
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
}

.number-container {
  margin-left: 1.14rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  @include get-theme.get-theme(color, blue-primary);
}

.last-box {
  margin-right: 1.14rem;
}

.number-text {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.71;
}

.current-page {
  @include get-theme.get-theme(background-color, white-text);
  @include get-theme.get-theme(border, white-text, 1px, solid);
  @include get-theme.get-theme(color, description-text);
}