@use "../../../theme/sass/get-theme";

.business-lines-heading-styling {
  margin-top: 5%;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5;
  @include get-theme.get-theme(color, black-text);
}

.business-lines-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  @include get-theme.get-theme(background-color, dark-grey);
  padding: 1.5rem;
  position: relative;
  align-items: center;
}

.business-lines-values {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 5rem;
  border: solid 0.063rem #b2b2b2;
  @include get-theme.get-theme(color, grey-shade-one);
  line-height: 1.71rem;
  font-size: 1rem;
  text-align: center;
}

.div-dropdown {
  width: 11.07rem;
  height: 13.42rem;
  overflow-y: scroll;
  scroll-behavior: auto;
  scrollbar-color: #27509b white;
  @include get-theme.get-theme(background-color, pure-white);
  position: absolute;
  display: block;
  z-index: 999;
}

.show-more-results {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 1rem;
  line-height: 1.71rem;
  @include get-theme.get-theme(color, text-dark);
}

.show-more-business-lines {
  @include get-theme.get-theme(color, blue-shade-one);
  text-decoration: underline;
  height: fit-content;
  align-items: center;
}

.show-more-number {
  padding: 1rem;
  @include get-theme.get-theme(color, blue-shade-one);
  line-height: 1.71rem;
  font-size: 1rem;
  text-align: center;
}
