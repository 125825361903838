@use "../../../theme/sass/get-theme";
@use "../../../theme/sass/button-theme";

.persona-details {
  display: flex;
  flex-direction: row;
  gap: 5%;
}
.persona-icon-container {
  width: 25%;

  .person-image {
    width: 100%;

    height: 6.8rem;
    border-radius: 1rem;
  }
}
.persona-details-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.persona-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.2rem;
  @include get-theme.get-theme(color, text-dark);
  font-weight: bold;
}
.persona-name {
  margin-top: 1.14rem;
  @include get-theme.get-theme(color, description-text);
  font-size: 0.9rem;
}

.about-persona {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1rem;
  @include get-theme.get-theme(color, text-grey);
  font-size: 1rem;
}

.persona-apps-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}
.app-logos {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
}
.app-icon-logo {
  height: 2rem;
  width: 2rem;

  img {
    height: 100%;
    width: 100%;
    border-radius: 2rem;
  }
}
.persona-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.more-apps {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  background-color: white;
  @include get-theme.get-theme(color, blue-primary);
  border-radius: 2rem;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  font-size: 1rem;
}

.persona-card-button {
  @include button-theme.secondary-button-theme;
  height: fit-content;
  align-items: center;
  padding: 10px;
}

.icon-container {
  gap: 1rem;
}
@media screen and (max-width: 1680px) {
  //handle responsiveness here
}
@media screen and (max-width: 1440px) {
  .app-icon-logo {
    height: 1.8rem;
    width: 1.8rem;

    img {
      height: 100%;
      width: 100%;
      border-radius: 2rem;
    }
  }
  .more-apps {
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 1.8rem;
    font-size: 0.8rem;
  }
  .persona-card-button {
    font-size: 0.7rem;
    padding: 6px;
  }
}
@media screen and (max-width: 1280px) {
  .persona-heading {
    font-size: 1rem;
  }
  .about-persona {
    font-size: 0.8rem;
  }
  .persona-name {
    font-size: 0.8rem;
  }
  .persona-icon-container {
    margin-right: 0.5rem;

    .person-image {
      width: 5rem;
      height: 5rem;
      border-radius: 10px;
    }
  }
  .app-icon-logo {
    height: 1.5rem;
    width: 1.5rem;

    img {
      height: 100%;
      width: 100%;
      border-radius: 2rem;
    }
  }
  .more-apps {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1.5rem;
    font-size: 0.8rem;
  }
  .persona-card-button {
    font-size: 0.7rem;
    padding: 6px;
  }
}
