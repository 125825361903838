@use "sass:map";

$themes: (
  default: (
    background-primary: rgba(242, 242, 242, 0.5),
    background-secondary: rgba(39, 80, 155, 0.05),
    blue-primary: #27509b,
    white-primary: #ffffff,
    dark-grey: rgba(39, 80, 155, 0.05),
    text-dark: #1a1a1a,
    text-red: #b71c1c,
    input-background: #f2f2f2,
    box-green: #50ae53,
    box-grey: #a3a3a3,
    white-text: #e5e5e5,
    card-name-text: #0d0d0d,
    description-text: #999999,
    border-grey: #ccc,
    green: #2e7d32,
    text-grey: #7f7f7f,
    text-dark-grey: #333333,
    grey-border: #b2b2b2,
    capsule-text: #404040,
    light-blue: rgba(39, 80, 155, 0.05),
    rating-text: #f8f8f8,
    black-text: #1a1a1a,
    black-text-shade: #000,
    deep-black-text: #333333,
    light-grey: #999999,
    grey-shade-one: #404040,
    grey-shade-two: #666666,
    grey-shade-three: #84848439,
    pure-white: white,
    blue-shade-one: #27509b,
    blue-shade-two: #bccff5,
    blue-shade-three: #27509b19,
    table-header-light-blue: "#eaf0ff",
    table-main-header-light-blue: "#f8faff",
    table-row-background-even: "#f5f7ff",
    table-row-background-odd: "#f8faff",
    input-background-shade-with-alpha: "#f2f2f280",
    silver-shade-one: #979797,
    grey-shade-four: #999,
    white-shade-one: #f4f6fa,
  ),
);

@mixin button-style {
  height: 3.2rem;
  @include get-theme.get-theme(background-color, blue-primary);
  color: white;
  border-radius: 4px;
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  margin-left: auto;
  padding: 12px 16px;
  font-size: 1rem;
}

@mixin get-theme($property, $key, $inlineExtra: null, $postExtra: null) {
  @each $theme-name, $theme-set in $themes {
    // do handling here
    $value: map-get(
      $map: $theme-set,
      $key: $key,
    );

    .theme-#{$theme-name} & {
      #{$property}: #{$inlineExtra} #{$value} #{$postExtra};
    }
  }
}
