@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.persona-status-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include get-theme.get-theme(background-color, white-primary);
  margin: 4% 0rem;
  width: 90%;
  flex: 1 0 auto;
}
.michelin-logo {
  height: 3rem;
  width: 17rem;
  margin-bottom: 2.5rem;
}

.persona-app-logo {
  width: 7.57rem;
  height: 7.71rem;
  margin-top: 1.33rem;
}

.persona-status-msg {
  font-size: 1.71rem;
  font-weight: bold;
  margin-top: 2.29rem;
  line-height: 2.57rem;
  @include get-theme.get-theme(color, green);
}

.persona-error-status-msg {
  font-size: 1.71rem;
  font-weight: bold;
  margin-top: 2.29rem;
  line-height: 2.57rem;
  @include get-theme.get-theme(color, text-red);
}

.persona-approval-details-container {
  margin-top: 2.89rem;
  width: 66.29rem;
  padding: 1.71rem;
  @include get-theme.get-theme(background-color, rating-text);
}

.persona-rejection-container {
  width: 68rem;
  font-size: 1rem;
  line-height: 1.71rem;
  margin-top: 2.29rem;
  @include get-theme.get-theme(color, black-text);
}

.persona-reject-feedback-input-container {
  margin-top: 2.89rem;
}

.reject-checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.persona-reject-submit-button {
  width: 7.99rem;
  height: 3.43rem;
  border-radius: 0.29rem;
  font-size: 1rem;
  border: none;
  margin-top: 4.14rem;
  margin-bottom: 4.14rem;
  @include get-theme.get-theme(color, white-primary);
  @include get-theme.get-theme(background-color, blue-primary);
}

.persona-reject-submit-button-container {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.persona-details-title {
  font-weight: bold;
  font-size: 1.14rem;
  line-height: 1.71rem;
  margin-bottom: 2.85rem;
}

.persona-details-label {
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.71rem;
  margin-right: 0.57rem;
  @include get-theme.get-theme(color, black-text);
}
.persona-details-value {
  font-size: 1rem;
  line-height: 1.71rem;
  @include get-theme.get-theme(color, grey-shade-two);
}

.persona-button-container {
  display: flex;
  flex-direction: row;
  gap: 2.29rem;
  margin-top: 13.86rem;
}

.login-button {
  @include button-theme.button-theme;
  width: 7.21rem;
  cursor: pointer;
}

@media only screen and (max-width: 1680px) {
  .persona-button-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 1280px) {
  .persona-status-container {
    margin: 2% 0rem;
  }
  .michelin-logo {
    height: 2.3rem;
    width: 11.8rem;
    margin-bottom: 1.5rem;
  }
  .persona-app-logo {
    width: 5.5rem;
    height: 5.6rem;
    margin-bottom: 0.8rem;
  }
  .persona-status-msg {
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 0.7rem;
    line-height: 1.5rem;
    @include get-theme.get-theme(color, green);
  }

  .persona-approval-details-container {
    margin-top: 2rem;
    width: 50rem;
    padding: 1.5rem;
    @include get-theme.get-theme(background-color, rating-text);
  }

  .persona-rejection-container {
    width: 51.5rem;
    font-size: 1rem;
    line-height: 1.71rem;
    @include get-theme.get-theme(color, black-text);
  }

  .persona-details-title {
    font-weight: bold;
    font-size: 1rem;
    line-height: 0.8rem;
    margin-bottom: 1.5rem;
  }

  .persona-details-label {
    font-weight: bold;
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin-right: 0.5rem;
    @include get-theme.get-theme(color, black-text);
  }

  .persona-details-value {
    font-size: 0.8rem;
    line-height: 0.8rem;
    @include get-theme.get-theme(color, grey-shade-two);
  }

  .persona-button-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
  }

  .login-button {
    width: 6rem;
  }
}
