@use "../../theme/sass/get-theme";

.card {
  padding: 1.4rem;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  @include get-theme.get-theme(background-color, white-primary);
  cursor: pointer;
  height: fit-content;
}

.card:hover {
  @include get-theme.get-theme(border, blue-primary, 1px, solid);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 120px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 100%;
}
.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.dropdown-icon {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
