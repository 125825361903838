@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.request-status-container {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include get-theme.get-theme(background-color, white-primary);
  margin: 4% 0rem;
  width: 90%;
  justify-content: space-around;
  flex: 1 0 auto;
}
.michelin-logo {
  height: 3rem;
  width: 17rem;
  margin-bottom: 2.5rem;
}

.request-app-logo {
  width: 10.6rem;
  height: 11.5rem;
  margin-bottom: 1.5rem;
}

.app-name {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.5rem;
  @include get-theme.get-theme(color, black-text);
}
.app-description-status-page {
  margin-top: 1rem;
  font-size: 1.3rem;
  @include get-theme.get-theme(color, grey-shade-two);
  text-align: center;
  width: 70%;
  margin-bottom: 1.5rem;
}
.approve-text {
  font-size: 1.5rem;
  font-weight: bold;
  @include get-theme.get-theme(color, green);
}
.status-msg {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
  @include get-theme.get-theme(color, black-text);
}
.reject-text {
  font-size: 1.5rem;
  font-weight: bold;
  @include get-theme.get-theme(color, text-red);
}

.status-button-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1rem;
}
.login-button {
  @include button-theme.button-theme;
  width: 10rem;
  cursor: pointer;
}
.close-button {
  @include button-theme.secondary-button-theme;
  width: 10rem;
  cursor: pointer;
}
.request-feedback-submit-button {
  @include button-theme.secondary-button-theme;
  width: 8rem;
  height: 2.8rem;
  margin-left: 87%;
  align-self: center;
  cursor: pointer;
  justify-content: flex-end;
}

.rejection-description {
  flex: display;
  flex-direction: column;
  width: 50%;
}

@media only screen and (max-width: 1799px) {
  .request-app-logo {
    width: 8rem;
    height: 9rem;
    margin-bottom: 1.5rem;
  }
  .michelin-logo {
    height: 2.5rem;
    width: 15rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }
  .app-name {
    font-size: 1.3rem;
  }
  .app-description-status-page {
    margin-top: 0.8rem;
    font-size: 1rem;
    width: 60%;
  }
  .status-msg {
    margin-top: 0.8rem;
    font-size: 1.1rem;
  }
  .approve-text {
    font-size: 1.3rem;
  }

  .reject-text {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 1280px) {
  .michelin-logo {
    height: 2.3rem;
    width: 11.8rem;
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }
  .request-app-logo {
    width: 5.5rem;
    height: 5.6rem;
    margin-bottom: 1.5rem;
  }
  .login-button {
    width: 8rem;
  }
  .close-button {
    width: 8rem;
  }
  .request-feedback-submit-button {
    width: 6.5rem;
    margin-left: 90%;
  }
}
