.popup-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile-popup {
  background-image: url("../../assets/images/card_backgrond.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0.9rem;
  align-self: flex-end;
  padding-left: 0.5rem;
  width: 25rem;
  height: 20rem;
  border-radius: 1.375rem;
  box-shadow: -7 0.375rem 1rem 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 999;
  overflow-wrap: anywhere;
}

.profile-popup-upper {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-bottom: 1rem;

  .profile-picture {
    width: 3.57rem;
    height: 3.57rem;
    border-radius: 50%;
  }

  .user-info {
    margin-left: 1.5rem;
    color: #27509b;

    .user-email {
      line-height: 1.71;
    }
  }
}

.profile-popup-lower {
  background-color: #2596be0d;
  text-align: center;
  margin-left: -0.3rem;
  margin-right: -0.313rem;
  padding: 3rem 0;
  border-bottom-left-radius: 1.5rem;

  .logout-button {
    padding: 0.75rem 2rem;
    background-color: rgb(0, 0, 0, 0);
    color: #27509b;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    border: solid 0.063rem #27509b;
    font-size: 1rem;
  }

  .logout-button:hover {
    background-color: #c6dbf1;
  }

  .mobile-platform {
    margin-top: 1rem;
    color: #27509b;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .version-name {
    margin-top: 0.25rem;
    color: #27509b;
    font-size: 0.625rem;
  }
}
