@use "sass:map";

@mixin button-theme {
  height: 3.2rem;
  background-color: #27509b;
  color: white;
  border-radius: 4px;
  border: #27509b 1px solid;
  // padding: 12px 16px;
  text-align: center;
  font-size: 1rem;
}
@mixin secondary-button-theme {
  height: 3.2rem;
  background-color: white;
  color: #27509b;
  border-radius: 4px;
  border: #27509b 1px solid;
  margin-left: auto;
  // padding: 12px;
  text-align: center;
  font-size: 1rem;
}
