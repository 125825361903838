@use "../../../theme/sass/get-theme";

.analytics-by-status-heading {
  width: 95%;
  margin-top: 2.3rem;
  align-self: center;
  font-weight: bold;
}
.analytics-by-status-container {
  width: 95%;
  align-self: center;
  margin-top: 2.3rem;
  margin-bottom: 2.3rem;
}

.analytics-by-status-app-container {
  width: 70%;
  display: flex;
  flex-direction: column; /* Stack count above the list */
  gap: 1.5rem;
}

.total-app-count {
  font-size: 1.1rem;
  margin-bottom: 10px;
  @include get-theme.get-theme(color, blue-primary);
  width: 100%;
  display: flex;
  justify-content: flex-start; /* Aligns count to the right */
}

.app-list {
  display: grid;
  /* 2 cards per row */
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 2rem;
}

.analytics-by-status-filter-container {
  @include get-theme.get-theme(background-color, white-primary);
  width: 30%;
  height: fit-content;
  margin-right: 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .filter-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
    width: 92%;

    align-items: center;
    margin-bottom: 1.5rem;
  }
  .filter-icon-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    align-items: center;
    .filter-icon {
      height: 1.3rem;
      width: 1.3rem;
    }
  }
  .reset-all-text {
    font-size: 1.2rem;
    cursor: pointer;
    @include get-theme.get-theme(color, blue-primary);
  }
  .select-date-container {
    width: 92%;
    .select-date-heading {
      @include get-theme.get-theme(color, text-grey);
      font-size: 1.2rem;
      font-weight: bold;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .select-date-dropdown {
    @include get-theme.get-theme(background-color, input-background);
    margin-bottom: 1.5rem;
  }
  .select-date-dropdown-text {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
  }
  .select-date-period {
    padding: 1rem;
    cursor: pointer;
    flex: 1;
    font-size: 1.1rem;
    @include get-theme.get-theme(color, text-dark);
  }
  .select-date-period:hover {
    @include get-theme.get-theme(background-color, white-text);
  }
}
.analytics-by-status-empty-message {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.analytics-by-status-app-container {
  width: 70%;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex-wrap: wrap;
  align-content: baseline;
  align-items: baseline;
}

.date-selection-container {
  width: 100%;
  padding-right: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .date-range-controls {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
    justify-content: space-around;

    .date-range-control {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      cursor: pointer;
      .calendar-input {
        font-size: 1rem;
        padding: 1.1rem;

        @include get-theme.get-theme(background-color, input-background);
        border: none;
        @include get-theme.get-theme(color, text-grey);

        pointer-events: none;
      }
      .calendar-input:focus {
        line-height: normal;
      }
    }
  }

  .calendar-label {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 1280px) {
  .analytics-by-status-filter-container {
    margin-right: 1rem;
  }
  .analytics-by-status-heading {
    width: 97%;
  }
  .analytics-by-status-container {
    width: 97%;
  }
}
