@use "../../../../theme/sass/get-theme";
@use "../../../../theme/sass/button-theme";

.create-team-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.create-team-popup {
  width: 50%;
  max-height: 70%;
  @include get-theme.get-theme(background-color, white-primary);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.07);
  display: flex;
  flex-direction: column;
}
.create-team-popup-header {
  padding: 1.5rem;
  @include get-theme.get-theme(background-color, background-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-shrink: 0;
}

.create-team-heading {
  font-size: 1.5rem;
  font-weight: bold;
  @include get-theme.get-theme(color, text-dark);
}

.create-team-popup-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: auto;

  .create-team-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .create-team-delete-icon {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    align-self: flex-end;
    margin-bottom: 21px;
  }

  .add-member {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    letter-spacing: normal;
    color: #27509b;
    border: none;
    margin-left: 40px;
    margin-bottom: 36px;
    background-color: white;
  }

  .err-msg {
    color: red;
    min-height: 10px;
  }
}

.create-team-popup-footer {
  flex-shrink: 0;
  border: solid 1px rgba(132, 132, 132, 0.23);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;

  .cancel {
    @include button-theme.secondary-button-theme;
    cursor: pointer;
    width: 6rem;
  }

  .save {
    @include button-theme.button-theme;
    cursor: pointer;
    width: 6rem;
  }
}

.input-field-container {
  display: flex;
  flex-direction: column;
  width: 35%;
}
