@use "../../theme/sass/get-theme";
@use "../../theme/sass/button-theme";

.header {
  margin: 0;
  padding: 0;
  max-width: 100%;
  @include get-theme.get-theme(background-color, white-primary);
}

.header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  margin: 0 10%;
}

.register-button {
  @include button-theme.button-theme;
  width: 15.7rem;
  margin-left: auto;
  cursor: pointer;
}

.tabs {
  height: 5.429rem;
  @include get-theme.get-theme(border, white-primary, 0, solid);
  display: flex;
  align-items: center;
  padding: 0px 20px 0px 20px;
}

.selected {
  @include get-theme.get-theme(border-bottom, blue-primary, 4px, solid);
  @include get-theme.get-theme(background-color, dark-grey);
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.logo {
  height: 32px;
  width: 164px;
  margin-right: 40px;
}

.icon {
  width: 1.14rem;
  height: 1.14rem;
  margin: 0.286rem 1.14rem 0.286rem 0rem;
}

.user-icon {
  width: 24px;
  height: 24px;
  margin-left: 40px;
  cursor: pointer;
}

.user-profile-wrapper {
  margin-left: auto;
}

@media screen and (max-width: 1280px) {
  .tabs {
    height: 3.571rem;
  }

  .selected {
    @include get-theme.get-theme(border-bottom, blue-primary, 2px, solid);
  }

  .menu-text {
    font-size: 0.85rem;
  }

  .icon {
    width: 0.85rem;
    height: 0.85rem;
    margin: 0.214rem 0.286rem 0.214rem 0rem;
  }
}

@media screen and (max-width: 700px) {
  .header-nav {
    flex-direction: column;
  }
  .header {
    height: auto;
  }
  .tabs {
    margin: 0;
    height: 40px;
    margin-bottom: 10px;
  }
  .register-button {
    margin: 0 0 15px;
  }
  .user-icon {
    margin: 0;
  }
  .logo {
    margin: 0;
  }
}
