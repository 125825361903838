@use "../../../theme/sass/get-theme";

.app-meta-data {
  display: flex;
  padding: 1.5rem;
  justify-content: left;
  border-radius: 0.25rem;
  @include get-theme.get-theme(background-color, dark-grey);
  gap: 15rem;
}

.app-meta-data-labels {
  font-weight: bold;
  line-height: 1.71;
  margin-bottom: 0.5rem;
  @include get-theme.get-theme(color, black-text);
}

.app-meta-data-values {
  line-height: 1.29;
  @include get-theme.get-theme(color, grey-shade-two);
}

.app-meta-data-margin {
  margin-top: 1rem;
}

@media screen and (max-width: 1280px) {
  .app-meta-data {
    gap: 12rem;
  }
}
