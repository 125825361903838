@use "../../theme/sass/get-theme";

.dashboard-checkbox {
  width: 7.43rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 8px;

  .dashboard-checkbox-label {
    width: auto;
    height: 1.14rem;
    padding: 1.14rem;
    border-radius: 1.71rem;
    box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.14);
    @include get-theme.get-theme(background-color, white-primary);
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .custom-check-box-image {
    width: 100%;
    height: 100%;
    align-self: center;
    object-fit: contain;
  }

  input[type="radio"] {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  input[type="radio"]:checked+label {
    @include get-theme.get-theme(background-color, blue-primary);
  }
}

@media screen and (max-width: 610px) {
  .dashboard-checkbox {
    dashboard-checkbox-label {
      height: 15px;
      padding: 8px;
    }
  }
}