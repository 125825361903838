@use "../../../theme/sass/get-theme";

.card-new {
  width: 21.7rem;
  height: 2.86rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.14rem;
  border-radius: 0.29rem;
  @include get-theme.get-theme(background-color, background-primary);
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding: 0;
}

.image-title-container > div > img {
  width: 2.86rem;
  height: 2.86rem;
  border-radius: 0.286rem;
}

img.app-icon {
  width: 1rem;
  height: 1rem;
  margin: 0px;
}

.title {
  font-family: NotoSans;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  @include get-theme.get-theme(color, card-name-text);
}

.image-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.14rem;
}

@media screen and (max-width: 1680px) {
  .card-new {
    width: 19rem;
  }
}

@media screen and (max-width: 1280px) {
  .card-new {
    width: 15rem;
    height: 2.29rem;
    padding: 0.57rem;
  }

  .image-title-container > div > img {
    width: 2.29rem;
    height: 2.29rem;
    border-radius: 0.286rem;
  }

  img.app-icon {
    width: 0.71rem;
    height: 0.71rem;
  }
  .title {
    font-size: 0.71rem;
    line-height: 1.8;
  }

  .image-title-container {
    gap: 0.57rem;
  }
}
