@use "../../theme/sass/get-theme";

.filter-tab-container {
  width: 92%;
  display: flex;
  flex-direction: column;

  .filter-component-heading {
    @include get-theme.get-theme(color, text-grey);
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .filter-options {
    flex-wrap: wrap;
  }

  .filter-capsule-button {
    width: auto;
    margin: 0 1rem 1.14rem 0;
    padding: 0.43rem 1.2rem;
    border-radius: 5.7rem;
    @include get-theme.get-theme(border, blue-shade-three, 1px, solid);
    @include get-theme.get-theme(color, capsule-text);
    @include get-theme.get-theme(background-color, blue-shade-three);
    font-size: 1.1rem;
    line-height: 1.71;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .selected-filter {
    @include get-theme.get-theme(border, blue-primary, 1px, solid);
    @include get-theme.get-theme(color, white-primary);
    @include get-theme.get-theme(background-color, blue-primary);
  }
  .capsule-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
}
