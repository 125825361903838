.all-aps-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  /* 3 cards per row */
  gap: 1.71rem;
  margin-top: 1.71rem;
}

.column-gap {
  margin-right: 24px;
}

.empty-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .row {
    flex-flow: column wrap;
  }

  .column-gap {
    margin-right: 0;
  }
}

@media screen and (max-width: 930px) {
  .tabs-row {
    flex-direction: column;
    margin-bottom: 24px;
  }
}
